import React from "react";
import { makeStyles } from "@material-ui/core";
import spinnerGif from "../../images/spinner.gif";

const useStyles = makeStyles(() => ({
  spinner: {
    height: 100,
    width: 100,
    backgroundImage: `url(${spinnerGif})`,
    backgroundSize: "cover",
    top: "50%",
    left: "50%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
  },
}));

export default function Spinner() {
  const classes = useStyles();
  return <div className={classes.spinner} />;
}
