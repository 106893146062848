import React, { useState, useEffect, useCallback } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { ReactComponent as Logo } from "../../../images/Logo.svg";
import Background2 from "../../../images/Background2.svg";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import * as api from "../../../utils/service";
import Help from "../../Help/Help";
import paths from "../../../utils/paths";
import Spinner from "../../Spinner/Spinner";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    maxWidth: 1440
  },
  wrap: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  background: {
    backgroundImage: `url(${Background2})`,
    width: "100%",
    height: 1500,
    position: "absolute",
    zIndex: -1,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    top: 240
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    margin: "62px 0 32px 0",
    position: "relative"
  },
  card: {
    borderRadius: 4,
    width: "90%",
    margin: "auto",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    height: "auto",
    backgroundColor: "white",
    maxWidth: 672
  },
  errorPop: {
    left: "50%",
    width: 320,
    height: 90,
    zIndex: 500,
    marginTop: -100,
    position: "absolute",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
    marginLeft: -160,
    borderRadius: 4,
    backgroundColor: "white",
    opacity: 0,
    transition: "all 0.3s ease",
    "&::after": {
      content: `''`,
      position: "absolute",
      left: "48%",
      top: 90,
      width: "0",
      height: "0",
      border: "8px solid transparent",
      borderTopColor: "white",
      transition: "all 0.3s ease"
    }
  },
  errorPopText: {
    textAlign: "center",
    padding: 30,
    margin: "auto",
    marginTop: -5,
    zIndex: 1000,
    transition: "all 0.3s ease",
    opacity: 0
  },
  bookingCard: {
    width: "100%",
    height: 111,
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    justifyContent: "center",
    alignItems: "center",
    padding: 40
  },
  bookingRow: {
    display: "flex",
    flexDirection: "row",
    minWidth: "100%",
    minHeight: 55,
    borderBottom: "1px solid #f3f0f0"
  },
  bookingItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 600,
    fontStyle: "normal",
    fontSize: 14
  },
  bookingItemName: {
    width: 130
  },
  content: {
    margin: "auto",
    paddingLeft: 40,
    paddingRight: 40,
    maxWidth: 550,
    paddingTop: 10,
    paddingBottom: 30
  },
  title: {
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 42,
    lineHeight: "56px",
    textAlign: "center",
    marginBottom: 29
  },
  subtitle: {
    fontFamily: "Avenir Next LT Pro",
    fontStyle: "normal",
    fontSize: 14,
    textAlign: "center",
    marginBottom: 24,
    padding: 50,
    paddingBottom: 0
  },
  note: {
    paddingLeft: 40
  },
  confirmationText: {
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: 16,
    textAlign: "center",
    margin: "auto",
    padding: 20,
    maxWidth: 888,
    width: "80%"
  },
  buttonGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  button: {
    background: theme.brand,
    color: "white",
    width: 280,
    height: 62,
    margin: "16px 0 22px 0",
    backgroundColor: "black",

    "&:hover": {
      background: "#696969"
    }
  },
  help: {
    position: "absolute",
    top: "2px",
    right: "250px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      right: "0px"
    },
    [theme.breakpoints.down("xs")]: {
      right: "20px"
    }
  }
}));

interface BookingPreCallProps {
  date?: string;
  time?: string;
}

export default function BookingPreCall({ date, time }: BookingPreCallProps) {
  const classes = useStyles();
  const history = useHistory();
  const [error, showError] = useState(false);
  const { bookingUuid } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [scheduleDetail, setScheduleDetail] = useState({
    date: "",
    startTime: "",
    endTime: ""
  });

  const goToNextRoom = async () => {
    try {
      // const country = await geoLocationService();
      // const { result: updatedClient } = await api.updateClientStatus({
      //   uuid: bookingUuid,
      //   status: country.code.toUpperCase() === "SG" ? "PRPP" : "ARTKit"
      // });
      const { result: updatedClient } = await api.updateClientStatus({
        uuid: bookingUuid,
        status: "ARTKit"
      });
      history.push(paths(updatedClient));
    } catch (error) {
      console.log(error);
    }
  };

  const onClickButton = async () => {
    try {
      const { client } = await api.getClientByUuid({ uuid: bookingUuid });
      if (getMinutesDiff(client) <= 60) goToNextRoom();
      else {
        showError(true);
        setTimeout(() => {
          showError(false);
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchClient = useCallback(async () => {
    const { client } = await api.getClientByUuid({ uuid: bookingUuid });

    if (client?.status === "Booked") {
      setScheduleDetail({
        date: moment
          .utc(client.bookingDate)
          .utcOffset("+08:00")
          .format("dddd, DD MMM YYYY"),
        startTime: moment
          .utc(client.bookingDate)
          .utcOffset("+08:00")
          .format("HH:mm"),
        endTime: moment
          .utc(client.bookingDate)
          .utcOffset("+08:00")
          .add("30", "m")
          .format("HH:mm")
      });

      setIsLoading(false);
    } else history.push(paths(client));
  }, [history, bookingUuid]);

  const getMinutesDiff = (client: any) => {
    const currentTime = moment.utc(new Date()).utcOffset("+08:00");
    const bookingTimeInUtC = moment
      .utc(client?.bookingDate)
      .utcOffset("+08:00");
    return Math.abs(currentTime.diff(bookingTimeInUtC, "minutes"));
  };

  useEffect(() => {
    fetchClient().catch(console.log);
  }, [fetchClient]);

  let errorShow = { opacity: "0" };
  if (error) errorShow.opacity = "1";

  if (isLoading) return <Spinner />;

  return (
    <div className={classes.container}>
      <div className={classes.background} />
      <div className={classes.wrap}>
        <div className={classes.logo}>
          <Logo />
          <div className={classes.help}>
            <Help />
          </div>
        </div>
        <div className={classes.title}>Tele-ART</div>
        <div className={classes.card}>
          <div className={classes.subtitle}>
            You have an upcoming appointment:
          </div>
          <div className={classes.bookingCard}>
            <div className={classes.bookingRow}>
              <div
                className={`${classes.bookingItem} ${classes.bookingItemName}`}
              >
                Date:
              </div>
              <div className={classes.bookingItem}>{scheduleDetail.date}</div>
            </div>
            <div className={classes.bookingRow}>
              <div
                className={`${classes.bookingItem} ${classes.bookingItemName}`}
              >
                Time:
              </div>
              <div className={classes.bookingItem}>
                {scheduleDetail.startTime} - {scheduleDetail.endTime}
              </div>
            </div>
          </div>
          <div className={classes.confirmationText}>
            Ensure that you are ready for the appointment as scheduled. Please
            refresh the page or click the button below at appointed time to
            enter the waiting room.
          </div>
          <div style={errorShow} className={classes.errorPop}>
            <p style={errorShow} className={classes.errorPopText}>
              You may only enter the waiting room at your pre-selected
              appointment time.
            </p>
          </div>
          <div className={classes.buttonGroup}>
            <Button
              className={classes.button}
              data-cy-disconnect
              onClick={onClickButton}
            >
              I am ready!
            </Button>
          </div>
          <div className={classes.note}>Note:</div>
          <div>
            <ul className={classes.content}>
              <li>
                Ensure that you have allowed camera access on your device.
              </li>
              <li>
                You should be in a quiet room or enclosed area, with strong
                internet connection.
              </li>
              <li>You should have a government-approved ART kit with you.</li>
              <li>
                If you have flu-like symptoms, do ensure that you have seen a
                doctor prior to the Tele-ART appointment, in line with existing
                government protocol.
              </li>
              <li>
                As an unobstructed line of sight is needed throughout the
                Tele-ART appointment, we regret to inform you that should your
                call be disconnected, the session and results (if any) will have
                to be voided and we will be unable to upload your results into
                the MOH system.
              </li>
              <li>
                Should this happen, you can join back the same link and wait for
                the next session to begin.
              </li>
              <li>
                Please note that the call may be recorded for medical audit
                purposes.
              </li>
              <li>
                All medical information obtained will be treated in accordance
                with prevailing Singapore regulations.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
