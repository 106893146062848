import React, { useEffect, useRef } from "react";
import { Typography, Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import useChatContext from "../../../hooks/useChatContext/useChatContext";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import { useHistory, useParams } from "react-router-dom";
import paths from "../../../utils/paths";
import * as api from "../../../utils/service";

export default function DeviceSelectionScreen() {
  const { connect: chatConnect } = useChatContext();
  const history = useHistory();
  const {
    connect: videoConnect,
    room,
    isAcquiringLocalTracks
  } = useVideoContext();
  const { twilioToken, bookingUuid } = useParams();

  const ref = useRef({});
  ref.current = {
    twilioToken,
    bookingUuid,
    room,
    chatConnect,
    videoConnect
  };

  useEffect(() => {
    const {
      twilioToken,
      bookingUuid,
      room,
      chatConnect,
      videoConnect
    } = ref.current;
    const connectToRoom = async () => {
      const { client } = await api.getClientByUuid({ uuid: bookingUuid });
      if (client.status === "InSession") {
        videoConnect(twilioToken, bookingUuid).catch(console.info);
        process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== "true" &&
          chatConnect(twilioToken);
      } else history.push(paths(client));
    };
    if (!room && !isAcquiringLocalTracks) connectToRoom();
  }, [history, ref, isAcquiringLocalTracks]);

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ height: "100%" }}
      >
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography
            variant="body2"
            style={{ fontWeight: "bold", fontSize: "16px" }}
          >
            Joining Meeting
          </Typography>
        </div>
      </Grid>
    </>
  );
}
