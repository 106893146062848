import React from "react";

export default function NoteIcon1() {
  return (
    <svg
      width="45"
      height="35"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 44C9.8494 44 0 34.1506 0 22C0 9.8494 9.8494 0 22 0C34.1506 0 44 9.8494 44 22C44 34.1506 34.1506 44 22 44ZM22 39.6C26.6678 39.6 31.1444 37.7457 34.4451 34.4451C37.7457 31.1444 39.6 26.6678 39.6 22C39.6 17.3322 37.7457 12.8556 34.4451 9.55492C31.1444 6.25428 26.6678 4.4 22 4.4C17.3322 4.4 12.8556 6.25428 9.55492 9.55492C6.25428 12.8556 4.4 17.3322 4.4 22C4.4 26.6678 6.25428 31.1444 9.55492 34.4451C12.8556 37.7457 17.3322 39.6 22 39.6ZM22 17.6C22.5835 17.6 23.1431 17.8318 23.5556 18.2444C23.9682 18.6569 24.2 19.2165 24.2 19.8V30.8C24.2 31.3835 23.9682 31.9431 23.5556 32.3556C23.1431 32.7682 22.5835 33 22 33C21.4165 33 20.8569 32.7682 20.4444 32.3556C20.0318 31.9431 19.8 31.3835 19.8 30.8V19.8C19.8 19.2165 20.0318 18.6569 20.4444 18.2444C20.8569 17.8318 21.4165 17.6 22 17.6ZM22 15.4C21.4165 15.4 20.8569 15.1682 20.4444 14.7556C20.0318 14.3431 19.8 13.7835 19.8 13.2C19.8 12.6165 20.0318 12.0569 20.4444 11.6444C20.8569 11.2318 21.4165 11 22 11C22.5835 11 23.1431 11.2318 23.5556 11.6444C23.9682 12.0569 24.2 12.6165 24.2 13.2C24.2 13.7835 23.9682 14.3431 23.5556 14.7556C23.1431 15.1682 22.5835 15.4 22 15.4Z"
        fill="#00C3b5"
      />
    </svg>
  );
}
