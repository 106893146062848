import React from "react";
import HelpOutlinedIcon from "@material-ui/icons/HelpOutlined";

export default function Help() {
  const help = () => {
    window.open(
      "https://api.whatsapp.com/send?phone=6581808948&text=Hi,%20I%20would%20like%20support%20regarding%20my%20Tele-ART."
    );
  };

  return (
    <div onClick={help}>
      <HelpOutlinedIcon />
    </div>
  );
}
