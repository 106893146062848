import React, { FormEvent, useEffect, useState, useCallback } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import LogoBlackIcon from "../../../icons/LogoBlackIcon";
import BgKitIcon from "../../../icons/BgKitIcon";
import { useHistory, useParams } from "react-router-dom";
import * as api from "../../../utils/service";
import Help from "../../Help/Help";
import paths from "../../../utils/paths";
import Spinner from "../../Spinner/Spinner";

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: "1em"
  },
  passcodeContainer: {
    minHeight: "50px",
    position: "relative"
  },
  submitButton: {
    color: "#fff !important",
    padding: "13px 30px",
    fontSize: "24px",
    marginTop: "25px",
    fontWeight: 600,
    fontFamily: "Avenir Next LT Pro",
    letterSpacing: "1.5px",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  btnContinue: {
    background: "#000 !important",
    fontSize: "24px",
    fontWeight: 600,
    fontFamily: "Avenir Next LT Pro"
  },
  btnCoDis: {
    background: "#D9D9D9 !important",
    fontSize: "24px",
    fontWeight: 600,
    fontFamily: "Avenir Next LT Pro"
  },
  btnOpen: {
    background: "#00C3B5 !important",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "24px",
    fontFamily: "Avenir Next LT Pro",
    padding: "20px 40px",
    marginBottom: "24px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 11px"
    }
  },
  fullWidth: {
    minWidth: "100%"
  },
  flexEnd: {
    justifyContent: "flex-end",
    display: "flex"
  },
  passcodeContainer1: {
    marginTop: "15px",
    position: "relative"
  },
  textBold: {
    fontWeight: "bold"
  },
  label: {
    fontSize: "14px"
  },
  textDirection: {
    borderBottom: "1px solid #282828",
    color: "#282828",
    marginTop: "10px",
    display: "block",
    width: "fit-content",
    fontWeight: 400,
    fontFamily: "Avenir Next LT Pro",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px"
    }
  },
  boxKit: {
    width: "485px",
    borderRadius: "4px",
    boxShadow: "0px 2px 4px 0px rgba(40, 42, 43, 0.3)",
    position: "relative",
    margin: "0 auto",
    padding: "40px 36px",
    background: "#fff",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      height: "auto",
      width: "calc(100% - 30px)",
      maxWidth: "400px"
    },
    "& svg": {
      position: "absolute",
      right: "15px",
      top: "15px",
      transform: "rotate(180deg)"
    }
  },
  kit: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Avenir Next LT Pro",
    overflow: "hidden",
    "& svg": {
      [theme.breakpoints.down("sm")]: {
        transform: "translateY(-50px)"
      }
    }
  },
  topKit: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    "& svg": {
      [theme.breakpoints.down("sm")]: {
        transform: "translateY(0px)"
      }
    }
  },
  contentKit: {
    position: "absolute",
    marginTop: "5%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0"
    },
    "& input": {
      position: "relative"
    }
  },
  titleText: {
    fontSize: "42px",
    fontWeight: 500,
    margin: "50px 0",
    fontFamily: "Avenir Next LT Pro"
  },
  parText: {
    fontSize: "24px",
    marginBottom: "15px",
    fontWeight: 600,
    fontFamily: "Avenir Next LT Pro"
  },
  desText: {
    fontSize: "16px",
    color: "#282828",
    lineHeight: "18px",
    fontWeight: 500,
    fontFamily: "Avenir Next LT Pro"
  },
  notText: {
    textDecoration: "underline"
  },
  help: {
    position: "absolute",
    top: "2px",
    right: "-210px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      right: "-150px"
    },
    [theme.breakpoints.down("xs")]: {
      right: "20px"
    }
  }
}));

export default function Registration() {
  const classes = useStyles();
  const history = useHistory();
  const { bookingUuid } = useParams();
  const [flag, setFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const regisContinue = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { result: updatedClient } = await api.updateClientStatus({
      uuid: bookingUuid,
      status: "ARTKit"
    });
    history.push(paths(updatedClient));
  };

  const registration = () => {
    window.open("https://php.healthhub.sg/HealthDeclaration?c=20M0266&df=true");
    setFlag(true);
  };

  const fetchClient = useCallback(async () => {
    const { client } = await api.getClientByUuid({ uuid: bookingUuid });
    if (client?.status === "PRPP") setIsLoading(false);
    else history.push(paths(client));
  }, [history, bookingUuid]);

  useEffect(() => {
    fetchClient().catch(console.log);
  }, [fetchClient]);

  if (isLoading) return <Spinner />;

  return (
    <div className={classes.kit}>
      <BgKitIcon />
      <div className={classes.contentKit}>
        <div className={classes.topKit}>
          <LogoBlackIcon />
          <div className={classes.help}>
            <Help />
          </div>
          <Typography variant="h2" className={classes.titleText}>
            Tele-ART
          </Typography>
        </div>
        <div className={classes.boxKit}>
          <Typography variant="h6" className={classes.parText}>
            Tele-ART Registration
          </Typography>
          <form onSubmit={regisContinue}>
            <Grid container justifyContent="space-between">
              <Grid item xs={12}>
                <div className={classes.passcodeContainer}>
                  <Typography variant="body1" className={classes.desText}>
                    Click the button below and fill in the registration form.
                  </Typography>
                  <Typography variant="body1" className={classes.desText}>
                    A pop-up window will appear.
                  </Typography>
                  <br />
                  <Typography variant="body1" className={classes.desText}>
                    Do <span className={classes.notText}>not</span> close this
                    browser.
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={registration}
                    className={`${classes.submitButton} ${classes.btnOpen}`}
                  >
                    Open registration form
                  </Button>
                  <Typography variant="body1" className={classes.desText}>
                    Once your registration is done, return to this browser and
                    click ‘Continue’
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              {flag ? (
                <Button
                  variant="contained"
                  type="submit"
                  className={`${classes.submitButton} ${classes.fullWidth} ${classes.btnContinue}`}
                >
                  Continue
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className={`${classes.submitButton} ${classes.fullWidth} ${classes.btnCoDis}`}
                >
                  Continue
                </Button>
              )}
            </Grid>
          </form>
        </div>
      </div>
    </div>
  );
}
