import React, { useState } from "react";
import {
  DEFAULT_VIDEO_CONSTRAINTS,
  SELECTED_VIDEO_INPUT_KEY
} from "../../../constants";
import { FormControl, MenuItem, Typography, Select } from "@material-ui/core";
import { LocalVideoTrack } from "twilio-video";
import {
  makeStyles,
  createTheme,
  ThemeProvider
} from "@material-ui/core/styles";
import VideoTrack from "../../VideoTrack/VideoTrack";
import useDevices from "../../../hooks/useDevices/useDevices";
import useMediaStreamTrack from "../../../hooks/useMediaStreamTrack/useMediaStreamTrack";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import VideoIcon from "../../../icons/VideoIcon";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const theme = createTheme({
  overrides: {
    MuiListItem: {
      root: {
        "&.Mui-selected": {
          backgroundColor: "#E1F3FE"
        },
        "&:hover": {
          backgroundColor: "#F5F5F5!important"
        }
      }
    }
  }
});

const useStyles = makeStyles({
  preview: {
    width: "300px",
    maxHeight: "200px",
    margin: "0.5em auto",
    "& video": {
      maxHeight: "200px"
    }
  },
  boxAudio: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #C7C7C7",
    borderRadius: "4px",
    paddingLeft: "0px",
    paddingTop: "5px",
    marginBottom: "15px",
    fontFamily: "Avenir Next LT Pro",
    position: "relative",
    "& svg": {
      position: "absolute",
      left: "5px",
      top: "20px"
    },
    "& div[class*='selectMenu']": {
      paddingLeft: "34px"
    }
  },
  inputSelect: {
    width: "100%",
    position: "relative",
    "& fieldset": {
      border: "none"
    },
    "& svg": {
      right: "10px !important",
      top: "10px !important",
      left: "unset",
      fill: "#282828",
      width: "30px",
      height: "30px"
    }
  },
  textTitle: {
    fontSize: "10px",
    marginLeft: "34px",
    marginBottom: "-7px",
    color: "#828282"
  },
  inputSelect1: {
    padding: "16px 16px !important",
    fontSize: "14px"
  },
  textNoLocal: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  textPadding: {
    marginLeft: "12px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingLeft: "24px"
  },
  selectOptions: {
    position: "relative",
    "& .MuiListItem-root": {
      borderRadius: 0
    },
    "& .MuiList-padding": {
      padding: "0 !important"
    },
    backgroundColor: "#fff",
    padding: "0 !important",
    margin: "0 !important",
    borderRight: "1px solid #C7C7C7",
    borderLeft: "1px solid #C7C7C7",
    borderBottom: "1px solid #C7C7C7",
    boxShadow: "none"
  }
});

export default function VideoInputList(props: { hidePreview: any }) {
  const hidePreview = props.hidePreview;
  const classes = useStyles();
  const { videoInputDevices } = useDevices();
  const { localTracks } = useVideoContext();

  const MenuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        maxHeight: "auto",
        width: 240
      }
    }
  };
  MenuProps.PaperProps.className = classes.selectOptions;
  const localVideoTrack = localTracks.find(track => track.kind === "video") as
    | LocalVideoTrack
    | undefined;
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
  );
  const localVideoInputDeviceId =
    mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;

  function replaceTrack(newDeviceId: string) {
    // Here we store the device ID in the component state. This is so we can re-render this component display
    // to display the name of the selected device when it is changed while the users camera is off.
    setStoredLocalVideoDeviceId(newDeviceId);
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    localVideoTrack?.restart({
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      deviceId: { exact: newDeviceId }
    });
  }

  return (
    <div className={hidePreview ? classes.boxAudio : ""}>
      {hidePreview ? <VideoIcon /> : ""}
      {localVideoTrack && !hidePreview && (
        <div className={classes.preview}>
          <VideoTrack isLocal track={localVideoTrack} />
        </div>
      )}
      {videoInputDevices.length > 1 ? (
        <div className={hidePreview ? classes.inputSelect : ""}>
          <ThemeProvider theme={theme}>
            <FormControl fullWidth>
              {hidePreview ? (
                <Typography
                  className={classes.textTitle}
                  variant="subtitle2"
                  gutterBottom
                >
                  Camera
                </Typography>
              ) : (
                <Typography variant="subtitle2" gutterBottom>
                  Video Intput
                </Typography>
              )}

              <Select
                onChange={e => replaceTrack(e.target.value as string)}
                value={localVideoInputDeviceId || ""}
                variant="outlined"
                MenuProps={MenuProps}
                IconComponent={hidePreview ? KeyboardArrowDownIcon : undefined}
              >
                {videoInputDevices.map(device => (
                  <MenuItem
                    value={device.deviceId}
                    key={device.deviceId}
                    className={classes.inputSelect1}
                  >
                    {device.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ThemeProvider>
        </div>
      ) : (
        <div>
          {hidePreview ? (
            <Typography
              className={classes.textTitle}
              variant="subtitle2"
              gutterBottom
            >
              Camera
            </Typography>
          ) : (
            <Typography variant="subtitle2" gutterBottom>
              Video Intput
            </Typography>
          )}
          <Typography
            className={hidePreview ? classes.textPadding : classes.textNoLocal}
          >
            {localVideoTrack?.mediaStreamTrack.label || "No Local Video"}
          </Typography>
        </div>
      )}
    </div>
  );
}
