import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  Participant,
  RemoteAudioTrack,
  RemoteVideoTrack
} from "twilio-video";
import Button from "@material-ui/core/Button";

//import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import AvatarIcon from "../../icons/AvatarIcon";
// import mockData from "../../../server/__mocks__/data";
//import NetworkQualityLevel from "../NetworkQualityLevel/NetworkQualityLevel";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import useIsRecording from "../../hooks/useIsRecording/useIsRecording";
import useIsTrackSwitchedOff from "../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff";
import useParticipantIsReconnecting from "../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting";
import usePublications from "../../hooks/usePublications/usePublications";
import useScreenShareParticipant from "../../hooks/useScreenShareParticipant/useScreenShareParticipant";
import useTrack from "../../hooks/useTrack/useTrack";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import LogoIcon from "../../icons/LogoIcon";
import RecordingIcon from "../../icons/RecordingIcon";
import { useAppState } from "../../state";
import PatientInfo from "./PatientInfo";
import { useHistory, useParams } from "react-router-dom";
import * as api from "../../utils/service";
import CompletedPopup from "../Popup/CompletedPopup";
import { Hidden } from "@material-ui/core";
import useRole from "../../state/useRole/useRole";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex"
  },
  identity: {
    background: "rgba(0, 0, 0, 0.5)",
    color: "white",
    padding: "10px",
    display: "inline-flex",
    "& svg": {
      marginLeft: "0.3em"
    },
    marginRight: "0.4em",
    alignItems: "center"
  },
  infoContainer: {
    position: "absolute",
    zIndex: 2,
    width: "100%",
    bottom: 0
  },
  reconnectingContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      top: "-40px",
      right: "-30px",
      bottom: "unset",
      left: "unset"
    },
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      top: "1px",
      right: 0,
      bottom: 0,
      left: 0
    }
  },
  fullWidth: {
    gridArea: "1 / 1 / 2 / 3",
    [theme.breakpoints.down("sm")]: {
      gridArea: "1 / 1 / 3 / 3"
    }
  },
  avatarContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#282828",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    "& svg": {
      transform: "scale(2)"
    }
  },
  recordingIndicator: {
    position: "absolute",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    background: "rgba(0, 0, 0, 0.5)",
    color: "white",
    padding: "0.1em 0.3em 0.1em 0",
    fontSize: "1.2rem",
    height: "28px",
    [theme.breakpoints.down("sm")]: {
      bottom: "auto",
      right: 0,
      top: 0
    }
  },
  circle: {
    height: "12px",
    width: "12px",
    background: "red",
    borderRadius: "100%",
    margin: "0 0.6em",
    animation: `1.25s $pulsate ease-out infinite`
  },
  box: {
    height: "495px",
    maxHeight: "493px",
    width: "100%",
    position: "relative",
    border: "1px solid white",
    margin: "0 7px 0 0",
    maxWidth: "50%",
    [theme.breakpoints.down("md")]: {
      height: "493px",
      marginTop: 35
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "51%",
      maxHeight: "100%",
      marginTop: 35
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      maxHeight: "45%",
      marginTop: 35
    }
  },
  box1: {
    height: "100%",
    maxHeight: "85%",
    width: "100%",
    position: "relative",
    border: "1px solid white",
    margin: "0 7px 0 0",
    maxWidth: "78%",
    background: "white",
    padding: "20px",
    [theme.breakpoints.down("md")]: {
      height: "493px",
      marginTop: 35
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "51%",
      maxHeight: "100%",
      margin: "0",
      marginTop: 35
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      // maxHeight: "45%"
      minHeight: "239px",
      marginTop: 35
    }
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "30px"
  },
  contentTop: {
    marginBottom: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  recording: {
    color: "white",
    display: "flex",
    position: "absolute",
    top: "30px",
    right: "30px"
  },
  textWhite: {
    color: "white"
  },
  textTime: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "32px",
      paddingBottom: "10px"
    }
  },
  startTime: {
    display: "flex",
    alignItems: "center",
    padding: "0 15px",
    background: "transparent",
    width: "fit-content",
    bottom: 0,
    // flexDirection: 'column',
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      fontSize: "32px",
      padding: "15px"
    }
  },
  btnStart: {
    color: "#282828",
    background: "#fff",
    padding: "7px 13px",
    height: "42px",
    marginLeft: "15px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0px"
    }
  },
  btnCom: {
    color: "#fff",
    background: "#00C3B5",
    padding: "7px 13px",
    height: "42px",
    marginLeft: "15px",
    width: "105px"
  },
  btnFa: {
    color: "#fff",
    background: "#A70606",
    padding: "7px 13px",
    height: "42px",
    marginLeft: "15px",
    width: "105px"
  },
  boxBtn: {
    display: "flex",
    justifyContent: "space-between",
    zIndex: 30,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    }
  },
  boxBtnCom: {
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  boxBtnStart: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: "auto",
      marginTop: "10px"
    }
  },
  patiMobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block"
    }
  },

  "@keyframes pulsate": {
    "0%": {
      background: `#A90000`
    },
    "50%": {
      background: "#f00"
    },
    "100%": {
      background: "#A90000"
    }
  }
}));

interface MainParticipantInfoProps {
  participant: Participant;
  client: any;
  children: React.ReactNode;
}

export default function MainParticipantInfo({
  participant,
  client,
  children
}: MainParticipantInfoProps) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const { setIsTrackEnabled } = useAppState();
  const role = useRole();
  const [patient, setPatient] = useState(undefined);
  const localParticipant = room?.localParticipant;
  const isLocal = localParticipant === participant;
  const { bookingUuid } = useParams();

  useEffect(() => {
    setPatient(client);
  }, [client]);

  const screenShareParticipant = useScreenShareParticipant();
  const isRemoteParticipantScreenSharing =
    screenShareParticipant && screenShareParticipant !== localParticipant;

  const publications = usePublications(participant);
  const videoPublication = publications.find(
    p => !p.trackName.includes("screen") && p.kind === "video"
  );
  const screenSharePublication = publications.find(p =>
    p.trackName.includes("screen")
  );

  const videoTrack = useTrack(screenSharePublication || videoPublication);
  const isVideoEnabled = Boolean(videoTrack);
  setIsTrackEnabled(isVideoEnabled);

  const audioPublication = publications.find(p => p.kind === "audio");

  // eslint-disable-next-line
  const audioTrack = useTrack(audioPublication) as
    | LocalAudioTrack
    | RemoteAudioTrack
    | undefined;

  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack
  );
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const isRecording = useIsRecording();

  return (
    <div
      data-cy-main-participant
      data-cy-participant={participant.identity}
      className={clsx(classes.container, {
        [classes.fullWidth]: !isRemoteParticipantScreenSharing
      })}
    >
      <div className={classes.content}>
        <div className={classes.contentTop}>
          <LogoIcon />
          {!isRecording && (
            <div className={classes.reconnectingContainer}>
              <div className={classes.recording}>
                <RecordingIcon />
                <Typography
                  variant="body1"
                  data-cy-recording-indicator
                  className={classes.textWhite}
                >
                  Recording
                </Typography>
              </div>
            </div>
          )}
        </div>
        {role === "client" ? (
          <div className={classes.box}>
            <div className={classes.infoContainer}>
              <div style={{ display: "flex" }}>
                <div className={classes.identity}>
                  {/*<AudioLevelIndicator audioTrack={audioTrack} />*/}
                  <Typography variant="body1" color="inherit">
                    {participant.identity}
                    {isLocal && " (You)"}
                    {screenSharePublication && " - Screen"}
                  </Typography>
                </div>
                {/*<NetworkQualityLevel participant={participant} />*/}
              </div>
              {isRecording && (
                <Tooltip
                  title="All participants' audio and video is currently being recorded. Visit the app settings to stop recording."
                  placement="top"
                >
                  <div className={classes.recordingIndicator}>
                    <div className={classes.circle}></div>
                    <Typography
                      variant="body1"
                      color="inherit"
                      data-cy-recording-indicator
                    >
                      Recording
                    </Typography>
                  </div>
                </Tooltip>
              )}
            </div>
            {(!isVideoEnabled || isVideoSwitchedOff) && (
              <div className={classes.avatarContainer}>
                <AvatarIcon />
              </div>
            )}
            {isParticipantReconnecting && (
              <div className={classes.reconnectingContainer}>
                <Typography variant="body1" style={{ color: "white" }}>
                  Reconnecting...
                </Typography>
              </div>
            )}
            {children}
          </div>
        ) : (
          <div className={classes.box1}>
            <div className={classes.infoContainer}>
              <div style={{ display: "flex" }}>
                <div className={classes.identity}>
                  {/*<AudioLevelIndicator audioTrack={audioTrack} />*/}
                  <Typography variant="body1" color="inherit">
                    {participant.identity}
                    {isLocal && " (You)"}
                    {screenSharePublication && " - Screen"}
                  </Typography>
                </div>
                {/*<NetworkQualityLevel participant={participant} />*/}
              </div>
              {isRecording && (
                <Tooltip
                  title="All participants' audio and video is currently being recorded. Visit the app settings to stop recording."
                  placement="top"
                >
                  <div className={classes.recordingIndicator}>
                    <div className={classes.circle}></div>
                    <Typography
                      variant="body1"
                      color="inherit"
                      data-cy-recording-indicator
                    >
                      Recording
                    </Typography>
                  </div>
                </Tooltip>
              )}
            </div>
            {(!isVideoEnabled || isVideoSwitchedOff) && (
              <div className={classes.avatarContainer}>
                <AvatarIcon />
              </div>
            )}
            {isParticipantReconnecting && (
              <div className={classes.reconnectingContainer}>
                <Typography variant="body1" style={{ color: "white" }}>
                  Reconnecting...
                </Typography>
              </div>
            )}
            {children}
          </div>
        )}
        <Hidden smDown>
          {role !== "client" && <PatientInfo content={patient} />}
        </Hidden>
        <div className={classes.boxBtn}>
          <div className={`${classes.boxBtnStart}`}></div>
          <div className={classes.patiMobile}>
            {role !== "client" && <PatientInfo content={patient} />}
          </div>
          <div className={`${classes.startTime} ${classes.boxBtnCom}`}>
            {role !== "client" && (
              <CompletedPopup bookingUuid={bookingUuid} client={patient} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
