import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTimer } from "react-timer-hook";
import * as api from "../../utils/service";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "row"
  },
  textWhite: {
    color: "white"
  },
  textTime: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "32px",
      margin: "10px 10px 10px 95px"
    }
  },
  startTime: {
    display: "flex",
    alignItems: "center",
    padding: "0 15px",
    background: "#373737",
    width: "fit-content",
    bottom: 0,
    // flexDirection: 'column',
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      fontSize: "32px",
      padding: "15px"
    }
  },
  btnStart: {
    color: "#282828",
    background: "#fff",
    padding: "7px 13px",
    height: "42px",
    marginLeft: "15px",
    marginTop: "7px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0px"
    },
    "&:hover": {
      backgroundColor: "#fff",
      color: "#144FE7"
    }
  }
}));

interface ClientTimerProps {
  timeStampClient: Date;
  startTimer: boolean;
}

export default function ClientTimer({
  timeStampClient,
  startTimer
}: ClientTimerProps) {
  const classes = useStyles();
  const { bookingUuid } = useParams();
  const { seconds, minutes, restart } = useTimer({
    autoStart: false,
    expiryTimestamp: timeStampClient
  });

  const secondTime = seconds < 10 ? `0${seconds}` : `${seconds}`;
  const minuteTime = minutes < 10 ? `0${minutes}` : `${minutes}`;

  useEffect(() => {
    if (timeStampClient) {
      restart(timeStampClient);
    }
  }, [timeStampClient, restart]);

  const startSupervisorTimer = async () => {
    await api.resetTimer({ uuid: bookingUuid });
  };

  return (
    <div className={classes.container}>
      <Typography
        variant="h3"
        data-cy-recording-indicator
        className={`${classes.textWhite} ${classes.textTime}`}
      >
        <span>{minuteTime}</span>:<span>{secondTime}</span>
      </Typography>
      <Button
        className={classes.btnStart}
        disabled={!timeStampClient}
        onClick={startSupervisorTimer}
      >
        {timeStampClient ? "Restart Timer" : "Start Timer"}
      </Button>
    </div>
  );
}
