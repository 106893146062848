import React from "react";

export default function Mic1Icon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33333 4.16667C7.33333 3.0616 7.77232 2.00179 8.55372 1.22039C9.33512 0.438987 10.3949 0 11.5 0C12.6051 0 13.6649 0.438987 14.4463 1.22039C15.2277 2.00179 15.6667 3.0616 15.6667 4.16667V10C15.6667 11.1051 15.2277 12.1649 14.4463 12.9463C13.6649 13.7277 12.6051 14.1667 11.5 14.1667C10.3949 14.1667 9.33512 13.7277 8.55372 12.9463C7.77232 12.1649 7.33333 11.1051 7.33333 10V4.16667ZM11.5 1.66667C10.837 1.66667 10.2011 1.93006 9.73223 2.3989C9.26339 2.86774 9 3.50363 9 4.16667V10C9 10.663 9.26339 11.2989 9.73223 11.7678C10.2011 12.2366 10.837 12.5 11.5 12.5C12.163 12.5 12.7989 12.2366 13.2678 11.7678C13.7366 11.2989 14 10.663 14 10V4.16667C14 3.50363 13.7366 2.86774 13.2678 2.3989C12.7989 1.93006 12.163 1.66667 11.5 1.66667ZM4.83333 9.16667C5.05435 9.16667 5.26631 9.25446 5.42259 9.41074C5.57887 9.56702 5.66667 9.77899 5.66667 10C5.66667 11.5471 6.28125 13.0308 7.37521 14.1248C8.46917 15.2188 9.9529 15.8333 11.5 15.8333C13.0471 15.8333 14.5308 15.2188 15.6248 14.1248C16.7188 13.0308 17.3333 11.5471 17.3333 10C17.3333 9.77899 17.4211 9.56702 17.5774 9.41074C17.7337 9.25446 17.9457 9.16667 18.1667 9.16667C18.3877 9.16667 18.5996 9.25446 18.7559 9.41074C18.9122 9.56702 19 9.77899 19 10C19.0004 11.8451 18.3206 13.6255 17.0906 15.0009C15.8607 16.3763 14.167 17.25 12.3333 17.455V19.1667C12.3333 19.3877 12.2455 19.5996 12.0893 19.7559C11.933 19.9122 11.721 20 11.5 20C11.279 20 11.067 19.9122 10.9107 19.7559C10.7545 19.5996 10.6667 19.3877 10.6667 19.1667V17.455C8.83301 17.25 7.13927 16.3763 5.90935 15.0009C4.67943 13.6255 3.99965 11.8451 4 10C4 9.77899 4.0878 9.56702 4.24408 9.41074C4.40036 9.25446 4.61232 9.16667 4.83333 9.16667Z"
        fill="#828282"
      />
    </svg>
  );
}
