import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import Background1 from "../../../images/Background1.svg";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    maxWidth: 1104,
    margin: "0 auto"
  },
  wrap: {
    width: "90%",
    maxWidth: 900,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    margin: "auto"
  },
  background: {
    backgroundImage: `url(${Background1})`,
    width: "100%",
    maxWidth: 1104,
    height: 950,
    position: "absolute",
    zIndex: -1,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    margin: "auto"
  },
  card: {
    borderRadius: 4,
    width: "90%",
    maxWidth: 672,
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: "white",
    padding: 40,
    margin: "auto",
    marginTop: 50,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  content: {
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: 18,
    lineHeight: "30px",
    textAlign: "center",
    margin: 30
  },
  result: {
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: 24,
    lineHeight: "30px",
    textAlign: "center",
    margin: 30
  },
  title: {
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: 56,
    marginTop: 20,
    lineHeight: "56px",
    textAlign: "center"
  },
  subtitle1: {
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 16,
    lineHeight: "30px",
    textAlign: "center",
    letterSpacing: 6,
    marginTop: 100
  }
}));

export default function SupervisorEnd() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.background} />
      <div className={classes.wrap}>
        <div className={classes.subtitle1}>TELE-ART</div>
        <div className={classes.title}>Call Ended</div>
        <div className={classes.card}>
          <div className={classes.result}>This session has been ended.</div>
          <div className={classes.content}>You may now close this window.</div>
        </div>
      </div>
    </div>
  );
}
