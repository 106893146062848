import React from "react";
import { makeStyles, Theme } from "@material-ui/core";

import { ReactComponent as Logo } from "../../images/Logo.svg";
import Background2 from "../../images/Background2.svg";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center"
  },
  wrap: {
    maxWidth: 672,
    display: "flex",
    flexDirection: "column"
  },
  background: {
    backgroundImage: `url(${Background2})`,
    width: "100%",
    height: 900,
    position: "absolute",
    zIndex: -1,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    top: 180
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    margin: "62px 0 32px 0"
  },
  card: {
    borderRadius: 4,
    width: "90%",
    maxWidth: 672,
    minHeight: 312,
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: "white",
    padding: 40,
    margin: "auto",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignContent: "center"
  },
  bookingCard: {
    width: "90%",
    maxWidth: 421,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  content: {
    margin: 0,
    paddingLeft: 20
  },
  title: {
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 42,
    lineHeight: "56px",
    textAlign: "center",
    marginBottom: 29
  },
  subtitle: {
    fontFamily: "Avenir Next LT Pro",
    fontStyle: "normal",
    fontSize: 24,
    fontWeight: 600,
    textAlign: "center",
    marginBottom: 24
  },
  confirmationText: {
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 16,
    textAlign: "center",
    margin: "16px 0 16px 0",
    minWidth: 156
  }
}));

export default function FourToFourPage() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.background} />
      <div className={classes.wrap}>
        <div className={classes.logo}>
          <Logo />
        </div>
        <div className={classes.title}>Tele-ART</div>
        <div className={classes.card}>
          <div className={classes.subtitle}>
            Oops! The page cannot be found.
          </div>
          <div className={classes.confirmationText}>
            Please check and try again. Make sure to enter the correct URL or
            select the link provided in the email/SMS sent.
          </div>
        </div>
      </div>
    </div>
  );
}
