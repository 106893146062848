import React from "react";

export default function WarningIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 50 47"
      fill="#282828"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 47C9.8494 47 0 37.1506 0 25C0 12.8494 9.8494 3 22 3C34.1506 3 44 12.8494 44 25C44 37.1506 34.1506 47 22 47ZM22 42.6C26.6678 42.6 31.1444 40.7457 34.4451 37.4451C37.7457 34.1444 39.6 29.6678 39.6 25C39.6 20.3322 37.7457 15.8556 34.4451 12.5549C31.1444 9.25428 26.6678 7.4 22 7.4C17.3322 7.4 12.8556 9.25428 9.55492 12.5549C6.25428 15.8556 4.4 20.3322 4.4 25C4.4 29.6678 6.25428 34.1444 9.55492 37.4451C12.8556 40.7457 17.3322 42.6 22 42.6ZM22 20.6C22.5835 20.6 23.1431 20.8318 23.5556 21.2444C23.9682 21.6569 24.2 22.2165 24.2 22.8V33.8C24.2 34.3835 23.9682 34.9431 23.5556 35.3556C23.1431 35.7682 22.5835 36 22 36C21.4165 36 20.8569 35.7682 20.4444 35.3556C20.0318 34.9431 19.8 34.3835 19.8 33.8V22.8C19.8 22.2165 20.0318 21.6569 20.4444 21.2444C20.8569 20.8318 21.4165 20.6 22 20.6ZM22 18.4C21.4165 18.4 20.8569 18.1682 20.4444 17.7556C20.0318 17.3431 19.8 16.7835 19.8 16.2C19.8 15.6165 20.0318 15.0569 20.4444 14.6444C20.8569 14.2318 21.4165 14 22 14C22.5835 14 23.1431 14.2318 23.5556 14.6444C23.9682 15.0569 24.2 15.6165 24.2 16.2C24.2 16.7835 23.9682 17.3431 23.5556 17.7556C23.1431 18.1682 22.5835 18.4 22 18.4Z"
        fill="#585858"
      />
      <circle
        cx="39"
        cy="11"
        r="6.5"
        fill="#00c3b5"
        stroke="#282828"
        strokeWidth="1.5"
      />
    </svg>
  );
}
