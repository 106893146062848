import React, { useState, useCallback, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { ReactComponent as Logo } from "../../../images/Logo.svg";
import Background1 from "../../../images/Background1.svg";
import * as api from "../../../utils/service";
import { useHistory, useParams } from "react-router-dom";
import Help from "../../Help/Help";
import paths from "../../../utils/paths";
import Spinner from "../../Spinner/Spinner";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    maxWidth: 1104,
    margin: "0 auto"
  },
  wrap: {
    width: "90%",
    maxWidth: 900,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    margin: "auto"
  },
  background: {
    backgroundImage: `url(${Background1})`,
    width: "100%",
    maxWidth: 1104,
    height: 950,
    position: "absolute",
    zIndex: -1,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    margin: "auto"
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    margin: "62px 0 32px 0",
    position: "relative"
  },
  content: {
    margin: 0,
    paddingLeft: 20,
    fontSize: 13
  },
  title: {
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 42,
    lineHeight: "56px",
    textAlign: "center"
  },
  subtitle1: {
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 600,
    fontStyle: "normal",
    fontSize: 16,
    lineHeight: "30px",
    textAlign: "center"
  },
  subtitle2: {
    fontFamily: "Avenir Next LT Pro",
    fontStyle: "normal",
    fontSize: 14,
    textAlign: "center",
    marginBottom: 24
  },
  help: {
    position: "absolute",
    top: "2px",
    right: "-45px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      right: "0px"
    },
    [theme.breakpoints.down("xs")]: {
      right: "0px"
    }
  }
}));

export default function WaitingRoom() {
  const classes = useStyles();
  const { bookingUuid } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const fetchClient = useCallback(async () => {
    const { client } = await api.getClientByUuid({ uuid: bookingUuid });
    if (client.status === "Waiting") return setIsLoading(false);
    if (client.status === "InSession" && !client.twilio?.accessTokens)
      return setIsLoading(false);
    history.push(paths(client));
  }, [history, bookingUuid]);

  useEffect(() => {
    fetchClient().catch(console.log);
  }, [fetchClient]);

  useEffect(() => {
    const interval = setInterval(async () => {
      fetchClient().catch(console.log);
    }, 5000);

    return () => clearInterval(interval);
  }, [fetchClient]);

  if (isLoading) return <Spinner />;

  return (
    <div className={classes.container}>
      <div className={classes.background} />
      <div className={classes.wrap}>
        <div className={classes.logo}>
          <Logo />
          <div className={classes.help}>
            <Help />
          </div>
        </div>
        <div className={classes.title}>Tele-ART</div>
        <div className={classes.subtitle1}>
          Please wait as your supervisor joins the call.
        </div>
        <div className={classes.subtitle2}>
          Your appointment will begin shortly.
        </div>
        <div>Note:</div>
        <div>
          <ul className={classes.content}>
            <li>Ensure that you have allowed camera access on your device.</li>
            <li>
              You should be in a quiet room or enclosed area, with strong
              internet connection.
            </li>
            <li>You should have a government-approved ART kit with you.</li>
            <li>
              If you have flu-like symptoms, do ensure that you have seen a
              doctor prior to the Tele-ART appointment, in line with existing
              government protocol.
            </li>
            <li>
              As an unobstructed line of sight is needed throughout the Tele-ART
              appointment, we regret to inform you that should your call be
              disconnected, the session and results (if any) will have to be
              voided and we will be unable to upload your results into the MOH
              system.
            </li>
            <li>
              Should this happen, you can join back the same link and wait for
              the next session to begin.
            </li>
            <li>
              Please note that the call may be recorded for medical audit
              purposes.
            </li>
            <li>
              All medical information obtained will be treated in accordance
              with prevailing Singapore regulations.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
