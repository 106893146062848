/* eslint-disable @typescript-eslint/no-shadow */
import MainParticipantInfo from "../MainParticipantInfo/MainParticipantInfo";
import ParticipantTracks from "../ParticipantTracks/ParticipantTracks";
import React, { useEffect, useState } from "react";
import useMainParticipant from "../../hooks/useMainParticipant/useMainParticipant";
import useSelectedParticipant from "../VideoProvider/useSelectedParticipant/useSelectedParticipant";
import useScreenShareParticipant from "../../hooks/useScreenShareParticipant/useScreenShareParticipant";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import ClientTimer from "../MainParticipantInfo/ClientTimer";
import SupervisorTimer from "../MainParticipantInfo/SupervisorTimer";
import { makeStyles, Theme } from "@material-ui/core/styles";
import * as api from "../../utils/service";
import moment from "moment";
import useRole from "../../state/useRole/useRole";
import { useParams } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) => ({
  timerContainer: {
    position: "absolute",
    left: "3%",
    top: "81vh",
    zIndex: 30,
    [theme.breakpoints.down("xs")]: {
      top: 60
    }
  }
}));

export default function MainParticipant() {
  const classes = useStyles();
  const mainParticipant = useMainParticipant();
  const { room } = useVideoContext();
  const { bookingUuid } = useParams();
  const localParticipant = room?.localParticipant;
  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const role = useRole();
  const [client, setClient] = useState(undefined);
  const [timer, setTimer] = useState<Date>();

  const videoPriority =
    (mainParticipant === selectedParticipant ||
      mainParticipant === screenShareParticipant) &&
    mainParticipant !== localParticipant
      ? "high"
      : null;

  useEffect(() => {
    const interval = setInterval(async () => {
      const { client: latestClient } = await api.getClientByUuid({
        uuid: bookingUuid
      });

      if (latestClient.timer) {
        const currentTime = moment(new Date()).millisecond(0);
        const serverTime = moment(new Date(latestClient?.timer)).millisecond(0);

        const differenceInSecond = currentTime.diff(serverTime, "seconds");
        const newTimer = currentTime
          .add(900 - differenceInSecond, "seconds")
          .toDate();
        if (!moment(newTimer).isSame(latestClient.timer)) setTimer(newTimer);
      }

      setClient(latestClient);
    }, 5000);

    return () => clearInterval(interval);
  }, [bookingUuid]);

  if (!client)
    return (
      <div>
        <CircularProgress variant="indeterminate" />
      </div>
    );

  return (
    /* audio is disabled for this participant component because this participant's audio 
       is already being rendered in the <ParticipantStrip /> component.  */
    <>
      <MainParticipantInfo participant={mainParticipant} client={client}>
        <ParticipantTracks
          participant={mainParticipant}
          videoOnly
          enableScreenShare={mainParticipant !== localParticipant}
          videoPriority={videoPriority}
          isLocalParticipant={mainParticipant === localParticipant}
        />
      </MainParticipantInfo>
      <div className={classes.timerContainer}>
        {role === "client" ? (
          <ClientTimer timeStampClient={timer} startTimer={!!timer} />
        ) : (
          <SupervisorTimer timeStampSupervisor={timer} />
        )}
      </div>
    </>
  );
}
