import React from "react";
import ReactDOM from "react-dom";

import { CssBaseline } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";

import App from "./App";
import AppStateProvider, { useAppState } from "./state";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import ErrorDialog from "./components/ErrorDialog/ErrorDialog";
import LoginPage from "./components/LoginPage/LoginPage";
import MainPage from "./components/MainPage/MainPage";
import GetNextClientPage from "./components/GetNextClientPage/GetNextClientPage";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import theme from "./theme";
import "./types";
import { ChatProvider } from "./components/ChatProvider";
import { VideoProvider } from "./components/VideoProvider";
import useConnectionOptions from "./utils/useConnectionOptions/useConnectionOptions";
import UnsupportedBrowserWarning from "./components/UnsupportedBrowserWarning/UnsupportedBrowserWarning";
import WaitingRoom from "./components/client/WaitingRoom/WaitingRoom";
import BookingPreCall from "./components/client/BookingPreCall/BookingPreCall";
import Client from "./components/client/Client";
import MissedPage from "./components/client/MissedPage/MissedPage";
import Failed from "./components/client/Failed/Failed";
import FourToFourPage from "./components/FourToFourPage/FourToFourPage";
import Disconnected from "./components/client/Disconnected/Disconnected";
import CompletedScreen from "./components/client/CompletedScreen/CompletedScreen";
import SupervisorEnd from "./components/client/SupervisorEnd/SupervisorEnd";
import UpdateTestKit from "./components/client/UpdateTestKit/UpdateTestKit";
import ClientHome from "./components/client/Home/Home";
import CheckDevices from "./components/client/CheckDevice/CheckDevices";
import Dashboard from "./components/Dashboard/Dashboard";
import Registration from "./components/client/Registration/Registration";
import WaWidget from "./components/WaWidget/WaWidget";
import Cancelled from "./components/client/Cancelled/Cancelled";

const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <ChatProvider>
        <App />
      </ChatProvider>
    </VideoProvider>
  );
};

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <UnsupportedBrowserWarning>
      <Router>
        <AppStateProvider>
          <Switch>
            <Route exact path="/">
              <ClientHome />
            </Route>
            <Route path="/connect/client/:bookingUuid/:twilioToken">
              <VideoApp />
            </Route>
            <PrivateRoute path="/connect/supervisor/:bookingUuid/:twilioToken">
              <VideoApp />
            </PrivateRoute>
            <PrivateRoute path="/provider/main">
              <MainPage />
            </PrivateRoute>
            <PrivateRoute exact path="/provider/dashboard">
              <Dashboard />
            </PrivateRoute>
            <PrivateRoute path="/next-client">
              <GetNextClientPage />
            </PrivateRoute>
            <Route path="/provider/login">
              <LoginPage />
            </Route>
            <Route exact path="/client/:bookingUuid">
              <Client />
            </Route>
            <Route exact path="/booking-precall/:bookingUuid">
              <WaWidget />
              <BookingPreCall />
            </Route>
            <Route exact path="/waiting-room/:bookingUuid">
              <WaWidget />
              <WaitingRoom />
            </Route>
            <Route exact path="/cancelled/:bookingUuid">
              <WaWidget />
              <Cancelled />
            </Route>
            <Route exact path="/missed-page/:bookingUuid">
              <WaWidget />
              <MissedPage />
            </Route>
            <Route exact path="/failed/:bookingUuid">
              <WaWidget />
              <Failed />
            </Route>
            <Route exact path="/disconnected/:bookingUuid">
              <WaWidget />
              <Disconnected />
            </Route>
            <Route exact path="/completed/:bookingUuid">
              <WaWidget />
              <CompletedScreen />
            </Route>
            <Route exact path="/supervisor-end">
              <SupervisorEnd />
            </Route>
            <Route exact path="/page-not-found">
              <WaWidget />
              <FourToFourPage />
            </Route>
            <Route exact path="/update-test-kit/:bookingUuid">
              <WaWidget />
              <UpdateTestKit />
            </Route>
            <Route exact path="/client-home">
              <WaWidget />
              <ClientHome />
            </Route>
            <Route exact path="/check-devices/:bookingUuid">
              <WaWidget />
              <CheckDevices />
            </Route>
            <Route exact path="/registration/:bookingUuid">
              <WaWidget />
              <Registration />
            </Route>
            <Redirect to="/" />
          </Switch>
        </AppStateProvider>
      </Router>
    </UnsupportedBrowserWarning>
  </MuiThemeProvider>,
  document.getElementById("root")
);
