import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Dialog, Typography, Slide } from "@material-ui/core";
import clsx from "clsx";
import * as api from "../../utils/service";
//import { useHistory } from "react-router-dom";
import { useAppState } from "../../state";
import useRole from "../../state/useRole/useRole";

export enum CovidStatus {
  Positive,
  Negative,
  Invalid,
  None
}

const useStyles = makeStyles((theme: Theme) => ({
  completedPopup: {
    display: "flex",
    flexDirection: "row",
    width: "439px",
    height: "84px",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#373737",
    padding: "16px 20px"
  },
  completedButton: {
    width: "146px",
    height: "42px",
    background: "#00C3B5",
    borderRadius: "4px",
    color: "#FFFFFF",
    fontFamily: "Avenir Next LT Pro",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "25px",
    "&:hover": {
      background: "#00ACA0"
    }
  },
  failedButton: {
    width: "146px",
    height: "42px",
    background: "#A70606",
    borderRadius: "4px",
    color: "#FFFFFF",
    fontFamily: "Avenir Next LT Pro",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "25px",
    "&:hover": {
      background: "#830a0a"
    }
  },
  text: {
    color: "#FFFFFF",
    width: "78px",
    fontSize: "14px",
    lineHeight: "19px",
    fontFamily: "Avenir Next LT Pro",
    fontStyle: "normal",
    fontWeight: 600
  },
  dialog: {
    padding: "40px"
  },
  title: {
    fontFamily: "Avenir Next LT Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "42px",
    lineHeight: "56px",
    textAlign: "center",
    letterSpacing: "1px",
    color: "#000000",
    marginBottom: "20px"
  },
  completed: {
    marginLeft: "6px",
    marginRight: "6px",
    color: "#00C3B5"
  },
  failed: {
    marginLeft: "6px",
    marginRight: "6px",
    color: "#A70606"
  },
  notif: {
    fontFamily: "Avenir Next LT Pro",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "16px",
    textAlign: "center",
    marginBottom: "24px"
  },
  description: {
    fontFamily: "Avenir Next LT Pro",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#282828",
    marginBottom: "24px"
  },
  completeDesc: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: 360,
    textAlign: "center",
    margin: "0 auto",
    marginBottom: 15
  },
  statusButtonGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginBottom: 20
  },
  buttonGroup: {
    height: "62px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  endCall: {
    width: "200px",
    height: "62px"
  },
  cancel: {
    width: "120px",
    height: "62px",
    border: "0.8px solid #000000",
    boxSizing: "border-box",
    borderRadius: "4px"
  },

  positive: {
    width: "110px",
    height: "62px",
    border: "2px solid #B70000",
    boxSizing: "border-box",
    background: "#FFEEEE",
    borderRadius: "4px",
    "&.active": {
      background: "#B70000",
      color: "#FFFFFF"
    }
  },
  negative: {
    width: "110px",
    height: "62px",
    border: "2px solid #00C3B5",
    boxSizing: "border-box",
    background: "#EFFFFE",
    borderRadius: "4px",
    "&.active": {
      background: "#00C3B5",
      color: "#FFFFFF"
    }
  },
  invalid: {
    width: "110px",
    height: "62px",
    border: "2px solid #EB9A20",
    boxSizing: "border-box",
    background: "#FFF7EC",
    borderRadius: "4px",
    "&.active": {
      background: "#EB9A20",
      color: "#FFFFFF"
    }
  },
  completedBtn: {
    marginRight: "8px",
    background: "#C7C7C7",
    color: "#FFFFFF",
    "&:hover": {
      background: "#C7C7C7"
    },
    "&.active": {
      background: "#262626",
      color: "#FFFFFF"
    }
  },
  clientInfor: {
    fontWeight: "normal"
  },
  failedBtn: {
    marginRight: "8px",
    background: "#A70606",
    color: "#FFFFFF",
    "&:hover": {
      background: "#830a0a"
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface CompletedPopupProps {
  bookingUuid: string;
  client: any;
}

export default function CompletedPopup({
  bookingUuid,
  client
}: CompletedPopupProps) {
  const classes = useStyles();
  const role = useRole();
  const [patient, setPatient] = useState(client);
  const [covidStatus, setCovidStatus] = useState<CovidStatus>(CovidStatus.None);
  const completeCall = async () => {
    try {
      const response = await api.completeSession({
        uuid: bookingUuid,
        status: "Disconnected",
        result: getCovidStatus()
      });
      if (role === "supervisor") {
        window.location.href = "/supervisor-end";
        return;
      }
      if (response.success === true) {
        window.location.href = "/completed";
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  const failSession = async () => {
    try {
      const response = await api.failSession({
        uuid: bookingUuid
      });
      if (role === "supervisor") {
        window.location.href = "/supervisor-end";
        return;
      }
      window.location.href = "/failed";
    } catch (e) {
      console.log("error", e);
    }
  };

  const getCovidStatus = () => {
    switch (covidStatus) {
      case CovidStatus.Positive:
        return "Positive";
      case CovidStatus.Negative:
        return "Negative";
      case CovidStatus.Invalid:
        return "Invalid";
      default:
        return "None";
    }
  };

  useEffect(() => {
    setPatient(client);
  }, [client]);

  const [clickCompletedButton, setClickCompletedButton] = useState({
    open: false,
    completed: false
  });

  const completeClick = () => {
    completeCall();
  };

  const failClick = () => {
    failSession();
  };

  return (
    <div>
      <div className={classes.completedPopup}>
        <Typography className={classes.text}>
          {"Tele-ART Completed:"}
        </Typography>
        <Button
          variant="contained"
          className={classes.completedButton}
          onClick={() => {
            setClickCompletedButton({ open: true, completed: true });
          }}
        >
          {"Completed"}
        </Button>
        <Button
          variant="contained"
          className={classes.failedButton}
          onClick={() => {
            setClickCompletedButton({ open: true, completed: false });
          }}
        >
          {"Failed"}
        </Button>
      </div>
      {clickCompletedButton.open && (
        <Dialog
          open={clickCompletedButton.open}
          TransitionComponent={Transition}
          keepMounted
        >
          <div className={classes.dialog}>
            <div className={classes.title}>
              <span>{"Session"}</span>
              <span
                className={
                  clickCompletedButton.completed
                    ? classes.completed
                    : classes.failed
                }
              >
                {clickCompletedButton.completed ? "Completed" : "Failed"}
              </span>
            </div>
            <div className={classes.notif}>
              <span
                className={
                  clickCompletedButton.completed
                    ? classes.completed
                    : classes.failed
                }
              >
                {clickCompletedButton.completed ? "" : "Failed:"}
              </span>
              <span>
                {clickCompletedButton.completed ? (
                  <div className={classes.clientInfor}>
                    Submit ART Result for <b>{patient?.name}</b>:
                  </div>
                ) : (
                  "This supervised ART session is invalid due to disconnection/obstruction of sight/faulty ART, etc."
                )}
              </span>
            </div>
            <div className={classes.statusButtonGroup}>
              <Button
                onClick={() => {
                  setCovidStatus(CovidStatus.Positive);
                }}
                className={`${classes.positive} ${
                  covidStatus === CovidStatus.Positive ? "active" : ""
                }`}
              >
                {"Positive"}
              </Button>
              <Button
                onClick={() => {
                  setCovidStatus(CovidStatus.Negative);
                }}
                className={`${classes.negative} ${
                  covidStatus === CovidStatus.Negative ? "active" : ""
                }`}
              >
                {"Negative"}
              </Button>
              <Button
                onClick={() => {
                  setCovidStatus(CovidStatus.Invalid);
                }}
                className={`${classes.invalid} ${
                  covidStatus === CovidStatus.Invalid ? "active" : ""
                }`}
              >
                {"Invalid"}
              </Button>
            </div>
            {clickCompletedButton.completed ? (
              <div className={classes.completeDesc}>
                <div>
                  To confirm and end the call, click on the Submit Result button
                  below.
                </div>
                <div>
                  To go back to select again, click on the Cancel button.
                </div>
              </div>
            ) : (
              <div className={classes.description}>
                <div>
                  {
                    "To confirm and end the call, click on the End Call button below."
                  }
                </div>
                <div>
                  {"To go back to select again, click on the Cancel button."}
                </div>
              </div>
            )}
            <div className={classes.buttonGroup}>
              <Button
                onClick={() => {
                  if (clickCompletedButton.completed) {
                    if (covidStatus !== CovidStatus.None) {
                      setClickCompletedButton({
                        open: false,
                        completed: false
                      });
                      completeClick();
                    }
                  } else {
                    setClickCompletedButton({ open: false, completed: false });
                    failClick();
                  }
                }}
                className={`${clsx(
                  classes.endCall,
                  clickCompletedButton.completed
                    ? classes.completedBtn
                    : classes.failedBtn
                )} ${covidStatus !== CovidStatus.None ? "active" : ""}`}
                data-cy-disconnect
              >
                {clickCompletedButton.completed ? "Submit Result" : "End Call"}
              </Button>
              <Button
                onClick={() => {
                  setClickCompletedButton({ open: false, completed: false });
                }}
                className={classes.cancel}
              >
                {"Cancel"}
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
}
