import React from "react";

export default function LogoBlackIcon() {
  return (
    <svg
      width="242"
      height="32"
      viewBox="0 0 242 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2577_3679)">
        <path
          d="M50.654 24.0565C50.5891 24.0243 50.5891 23.9921 50.5891 23.96L50.1995 20.0042C50.1995 19.9077 50.2644 19.8112 50.3293 19.8112C50.3618 19.8112 50.3943 19.8434 50.3943 19.8434C51.3359 20.3901 55.005 22.1911 61.077 22.1911C65.0058 22.1911 67.4411 21.2263 67.4411 19.8112C67.4411 18.8142 65.2981 17.8816 60.0054 17.8816C50.0696 17.8816 49.6475 13.765 49.6475 12.2856C49.6475 7.39716 55.6544 6.20721 60.8821 6.20721C65.5578 6.20721 68.8373 7.59013 69.4218 7.84742C69.4867 7.84742 69.4867 7.91174 69.4867 7.9439L69.7465 11.771C69.779 11.8354 69.6815 11.9318 69.6166 11.9318C69.5841 11.9318 69.5517 11.8997 69.5517 11.8997C68.3503 11.16 64.876 9.7449 60.8821 9.7449C54.3556 9.7449 53.2516 11.2565 53.2516 12.2856C53.2516 13.7328 55.8493 14.3439 60.0379 14.3439C63.8044 14.3439 71.2726 14.762 71.2726 19.9399C71.2726 21.1298 70.4284 25.7288 61.1094 25.7288C54.9726 25.761 51.2385 24.3137 50.654 24.0565Z"
          fill="#282828"
        />
        <path
          d="M164.527 25.3427C164.267 25.3427 164.072 25.1497 164.072 24.8924V7.04318C164.072 6.78589 164.267 6.59293 164.527 6.59293H174.073C181.379 6.59293 185.697 10.2914 185.697 15.9517C185.697 20.5507 182.84 25.3105 174.073 25.3105L164.527 25.3427ZM167.644 21.3869C167.644 21.612 167.839 21.8371 168.066 21.8371H174.041C179.853 21.8371 182.093 20.1969 182.093 16.016C182.093 10.9668 177.028 10.1949 174.041 10.1949H168.066C167.839 10.1949 167.644 10.3879 167.644 10.6452V21.3869Z"
          fill="#282828"
        />
        <path
          d="M192.062 15.984C192.062 9.03731 199.108 6.23932 203.848 6.23932C208.946 6.23932 215.667 9.35892 215.667 15.984C215.667 21.8051 210.602 25.7609 203.848 25.7609C198.978 25.7609 192.062 22.2232 192.062 15.984ZM195.633 15.984C195.633 19.4252 199.302 22.2232 203.848 22.2232C208.394 22.2232 212.096 19.4252 212.096 15.984C212.096 12.575 208.394 9.77701 203.848 9.77701C199.302 9.77701 195.633 12.575 195.633 15.984Z"
          fill="#282828"
        />
        <path
          d="M93.9043 6.62552H79.3901C79.2927 6.62552 79.1953 6.722 79.1953 6.85064V9.87376V9.93808C79.1953 10.0346 79.2927 10.131 79.4226 10.131H79.6824H82.9943H83.5788H93.9368C95.1382 10.131 96.2746 11.3853 96.2746 12.7361C96.3071 14.0225 95.1706 15.0838 93.9043 15.0838H83.6113H83.0268H80.8188H79.4551C79.3577 15.0838 79.2603 15.1803 79.2603 15.3089V16.2416V25.118C79.2603 25.2145 79.3577 25.3109 79.4551 25.3109H82.6047C82.7021 25.3109 82.7995 25.2145 82.7995 25.118V18.8466C82.7995 18.718 82.8644 18.6537 82.9943 18.6537H93.8394C97.1188 18.6537 99.8139 16.0808 99.8463 12.8647C99.8463 9.48783 97.1188 6.62552 93.9043 6.62552Z"
          fill="#282828"
        />
        <path
          d="M127.413 21.805H112.509C112.347 21.805 112.217 21.6442 112.217 21.4834V17.9457C112.217 17.8492 112.282 17.7527 112.412 17.7527H122.542C122.64 17.7527 122.737 17.6884 122.737 17.5919L123.744 14.408C123.776 14.3115 123.679 14.1829 123.549 14.1829H112.379C112.282 14.1829 112.185 14.0864 112.185 13.9899V10.4522C112.185 10.2914 112.314 10.1628 112.477 10.1628H124.75H126.374C126.439 10.1628 126.536 10.0985 126.569 10.0341L127.575 6.81805C127.64 6.72157 127.51 6.59293 127.381 6.59293H123.711H120.562H108.873C108.678 6.59293 108.548 6.72157 108.548 6.88237V25.0532C108.548 25.214 108.678 25.3427 108.873 25.3427H126.342C126.406 25.3427 126.504 25.2784 126.536 25.214L127.543 22.0301C127.64 21.9336 127.543 21.805 127.413 21.805Z"
          fill="#282828"
        />
        <path
          d="M155.338 21.805H140.434C140.272 21.805 140.142 21.6442 140.142 21.4834V17.9457C140.142 17.8492 140.207 17.7527 140.337 17.7527H150.467C150.565 17.7527 150.662 17.6884 150.662 17.5919L151.669 14.408C151.701 14.3115 151.604 14.1829 151.474 14.1829H140.304C140.207 14.1829 140.109 14.0864 140.109 13.9899V10.4522C140.109 10.2914 140.239 10.1628 140.402 10.1628H152.675H154.299C154.364 10.1628 154.461 10.0985 154.494 10.0341L155.5 6.81805C155.565 6.72157 155.435 6.59293 155.305 6.59293H151.636H148.487H136.797C136.603 6.59293 136.473 6.72157 136.473 6.88237V25.0532C136.473 25.214 136.603 25.3427 136.797 25.3427H154.266C154.331 25.3427 154.429 25.2784 154.461 25.214L155.468 22.0301C155.565 21.9336 155.468 21.805 155.338 21.805Z"
          fill="#282828"
        />
        <path
          d="M241.806 21.9017H240.182H239.273H238.007H233.785C227.908 21.9017 225.635 20.2293 225.635 16.0162C225.635 10.9027 230.766 10.1308 233.785 10.1308H238.007H239.273H240.182H240.799C240.864 10.1308 240.961 10.0987 240.994 10.0022L242 6.75393C242.065 6.65745 241.935 6.52881 241.806 6.52881H239.728H238.169H237.13H233.785C226.382 6.52881 222.031 10.2595 222.031 16.0162C222.031 20.6796 224.921 25.5037 233.785 25.5037H237.13H239.728H241.871C241.968 25.5037 242 25.4715 242 25.375V22.0946C242 21.966 241.935 21.9017 241.806 21.9017Z"
          fill="#282828"
        />
        <path
          d="M0.261164 21.0332H9.48269H13.7038L9.64504 31.1638C9.48269 31.6462 9.67751 31.9678 10.1321 31.9678H19.5809C20.003 31.9678 20.5225 31.5819 20.7174 31.1638L24.7437 21.001H36.1407C36.6602 21.001 37.1797 20.6472 37.3421 20.2613L40.6216 12.0281C40.7514 11.6422 40.4917 11.3528 39.9722 11.3528H28.5751L32.7638 0.80402C32.9261 0.321608 32.7313 0 32.2767 0L22.7955 0C22.3733 0 21.8538 0.38593 21.659 0.80402L17.4703 11.2884L17.4379 11.3528H17.2431H3.80041C3.47571 11.3528 3.44324 11.803 3.73547 11.8995L15.7819 14.0864C16.0741 14.1508 16.0741 14.601 15.7494 14.6332L2.30678 15.405C1.98208 15.405 1.94961 15.8553 2.24184 15.9518L13.996 17.5276L14.4182 17.592C14.7429 17.6241 14.7429 18.0744 14.4506 18.1387L13.996 18.2352L0.196223 20.4864C-0.0960081 20.5829 -0.0310678 20.9688 0.261164 21.0332Z"
          fill="#282828"
        />
      </g>
      <defs>
        <clipPath id="clip0_2577_3679">
          <rect width="242" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
