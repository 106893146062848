import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  patientInfo: {
    position: "absolute",
    width: "20%",
    right: "0",
    top: "35%",
    color: "white",
    fontFamily: "Avenir Next LT Pro",
    fontSize: "16px",
    background: "#373737",
    padding: "20px",
    height: "54%",
    marginRight: "30px",
    overflow: "auto",
    zIndex: 20,
    "&::-webkit-scrollbar": {
      width: "4px !important",
      height: "3px !important"
    },
    "&::-webkit-scrollbar-track-piece": {
      background: "#535353 !important"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#868E95 !important",
      height: "46px !important",
      borderRadius: "0px !important"
    },
    "&::after": {
      [theme.breakpoints.down("xs")]: {
        content: `''`,
        width: "15px",
        height: "15px",
        borderRight: "3px solid white",
        borderTop: "3px solid white",
        position: "absolute",
        top: "30px",
        right: "20px",
        transform: "rotate(135deg)"
      }
    },
    [theme.breakpoints.down("xs")]: {
      position: "relative",
      width: "100%",
      right: "0",
      top: "0",
      height: "auto",
      margin: "10px 0"
    }
  },
  textSpace: {
    paddingBottom: "15px",
    lineHeight: "26px"
  },
  boxItem: {
    display: "flex",
    justifyContent: "space-between"
  },
  content: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "unset"
    }
  },
  contentDesx: {
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  }
}));

export default function PatientInfo(content) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const patiClick = () => {
    setOpen(!open);
  };

  return (
    <div className={classes.patientInfo} onClick={() => patiClick()}>
      <Typography variant="h6" color="inherit" className={classes.textSpace}>
        Patient Information
      </Typography>
      <div className={classes.contentDesx}>
        <Typography
          variant="body1"
          color="inherit"
          className={classes.textSpace}
        >
          Name : <br /> {content?.content?.name}
        </Typography>
        <div>
          <div className={`${classes.boxItem} ${classes.textSpace}`}>
            <Typography variant="body1" color="inherit">
              NRIC : {content?.content?.nationalId || "N/A"}
            </Typography>
            <Typography variant="body1" color="inherit">
              D.O.B. :{" "}
              {content?.content?.dateOfBirth
                ? new Date(content?.content?.dateOfBirth).toLocaleDateString()
                : "N/A"}
            </Typography>
          </div>
          <div className={`${classes.boxItem} ${classes.textSpace}`}>
            <Typography variant="body1" color="inherit">
              Natinoality : {content?.content?.nationality || "N/A"}
            </Typography>
            <Typography variant="body1" color="inherit">
              Contact No.:{" "}
              {content?.content?.contactNo ||
                content?.content?.contact ||
                "N/A"}
            </Typography>
          </div>
        </div>
        <Typography
          variant="body1"
          color="inherit"
          className={classes.textSpace}
        >
          Address : <br /> {content?.content?.address || "N/A"}
        </Typography>
        <Typography
          variant="body1"
          color="inherit"
          className={classes.textSpace}
        >
          Test Kit Brand : {content?.content?.testKit?.brand || "N/A"}
        </Typography>
        <Typography
          variant="body1"
          color="inherit"
          className={classes.textSpace}
        >
          Test Kit Lot No.: {content?.content?.testKit?.lotNumber || "N/A"}
        </Typography>
      </div>
      {open && (
        <div className={classes.content}>
          <Typography
            variant="body1"
            color="inherit"
            className={classes.textSpace}
          >
            Name : <br /> {content?.content?.firstName}
          </Typography>
          <div>
            <div className={`${classes.boxItem} ${classes.textSpace}`}>
              <Typography variant="body1" color="inherit">
                NRIC : {content?.content?.nric || "N/A"}
              </Typography>
              <Typography variant="body1" color="inherit">
                D.O.B. : {content?.content?.dob || "N/A"}
              </Typography>
            </div>
            <div className={`${classes.boxItem} ${classes.textSpace}`}>
              <Typography variant="body1" color="inherit">
                Natinoality : {content?.content?.natinoality || "N/A"}
              </Typography>
              <Typography variant="body1" color="inherit">
                Contact No.: {content.content?.contact || "N/A"}
              </Typography>
            </div>
          </div>
          <Typography
            variant="body1"
            color="inherit"
            className={classes.textSpace}
          >
            Address : <br /> {content?.content?.address || "N/A"}
          </Typography>
          <Typography
            variant="body1"
            color="inherit"
            className={classes.textSpace}
          >
            Test Kit Brand : {content?.content?.testKitBrand || "N/A"}
          </Typography>
          <Typography
            variant="body1"
            color="inherit"
            className={classes.textSpace}
          >
            Test Kit Lot No.: {content?.content?.testKitLotNo || "N/A"}
          </Typography>
        </div>
      )}
    </div>
  );
}
