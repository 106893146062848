import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core";
import ChatWindow from "../ChatWindow/ChatWindow";
import ParticipantList from "../ParticipantList/ParticipantList";
import MainParticipant from "../MainParticipant/MainParticipant";
import BackgroundSelectionDialog from "../BackgroundSelectionDialog/BackgroundSelectionDialog";
import useChatContext from "../../hooks/useChatContext/useChatContext";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import NotWarning from "../NotWarning/NotWarning";
import MediaErrorSnackbar from "../PreJoinScreens/MediaErrorSnackbar/MediaErrorSnackbar";

const useStyles = makeStyles((theme: Theme) => {
  // const totalMobileSidebarHeight = `${theme.sidebarMobileHeight +
  //   theme.sidebarMobilePadding * 2 +
  //   theme.participantBorderWidth}px`;
  return {
    container: {
      position: "relative",
      height: "100%",
      display: "grid",
      gridTemplateColumns: `1fr 1fr`,
      gridTemplateRows: "100%",
      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: `1fr 1fr 150px`,
        // gridTemplateRows: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`
        gridTemplateRows: `1fr 1fr`
      },
      [theme.breakpoints.down("xs")]: {
        gridTemplateColumns: `100%`,
        // gridTemplateRows: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`
        gridTemplateRows: `1fr 1fr`
      }
    },
    rightDrawerOpen: { gridTemplateColumns: `1fr 1fr 1fr` }
  };
});

export default function Room() {
  const [data] = useState("Notes:");
  const classes = useStyles();
  const { isChatWindowOpen, isRFWindowOpen } = useChatContext();
  const {
    isBackgroundSelectionOpen,
    getAudioAndVideoTracks
  } = useVideoContext();
  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    if (!mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log("Error acquiring local media:");
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, mediaError]);

  return (
    <div
      className={clsx(classes.container, {
        [classes.rightDrawerOpen]:
          isChatWindowOpen || isBackgroundSelectionOpen || isRFWindowOpen
      })}
    >
      <MediaErrorSnackbar error={mediaError} />
      <MainParticipant />
      <ParticipantList />
      <ChatWindow />
      <NotWarning content={data} />
      <BackgroundSelectionDialog />
    </div>
  );
}
