import React, { useRef, useState, useEffect } from "react";
import { useAppState } from "../../state";
import { Button, Dialog, Slide, Theme } from "@material-ui/core";
import Background1 from "../../images/Background1.svg";
import { makeStyles } from "@material-ui/core/styles";
import * as api from "../../utils/service";
import clsx from "clsx";
import moment from "moment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DataGrid, GridToolbar, GridColDef } from "@mui/x-data-grid";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider
} from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { SelectedParticipantProvider } from "../VideoProvider/useSelectedParticipant/useSelectedParticipant";
export enum CovidStatus {
  Positive,
  Negative,
  Invalid,
  None
}

const useStyles = makeStyles((theme: Theme) => ({
  googleButton: {
    background: "white",
    color: "rgb(0, 94, 166)",
    borderRadius: "4px",
    border: "2px solid rgb(2, 122, 197)",
    margin: "1.8em 0 0.7em",
    textTransform: "none",
    boxShadow: "none",
    padding: "0.3em 1em",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    "&:hover": {
      background: "white",
      boxShadow: "none"
    }
  },
  parent: {
    width: "100%",
    height: "100%",
    minHeight: 500
  },
  header: {
    height: "7%",
    paddingLeft: 30,
    paddingRight: 30,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "center",
    justifyContent: "space-between",
    alignItems: "center"
  },
  headerImage: {
    maxHeight: 25
  },
  body: {
    height: "93%"
  },
  rangePicker: {
    minWidth: 530,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "center",
    justifyContent: "space-between",
    alignItems: "center"
  },
  errorMessage: {
    color: "red",
    display: "flex",
    alignItems: "center",
    margin: "1em 0 0.2em",
    "& svg": {
      marginRight: "0.4em"
    }
  },
  tableCell: {
    border: "1px solid #282828",
    textAlign: "left"
  },
  table: {
    width: "100%"
  },
  gutterBottom: {
    marginBottom: "1em"
  },
  passcodeContainer: {
    minHeight: "50px"
  },
  submitButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    background: "#000"
  },
  fullWidth: {
    minWidth: "100%"
  },
  flexEnd: {
    justifyContent: "flex-end",
    display: "flex"
  },
  passcodeContainer1: {
    minHeight: "100px",
    marginTop: "15px"
  },
  textBold: {
    fontWeight: "bold"
  },
  container: {
    width: "100%",
    maxWidth: 1104,
    margin: "0 auto"
  },
  background: {
    backgroundImage: `url(${Background1})`,
    width: "100%",
    maxWidth: 1104,
    height: 950,
    position: "absolute",
    zIndex: -1,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    margin: "auto",
    marginTop: -100
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    margin: "0 0 32px 0"
  },
  content: {
    margin: 0,
    paddingLeft: 20,
    fontSize: 13
  },
  subtitle1: {
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: 16,
    lineHeight: "30px",
    textAlign: "center",
    marginBottom: 21
  },
  card: {
    borderRadius: 4,
    width: "90%",
    maxWidth: 672,
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: "white",
    padding: 40,
    margin: "auto",
    marginTop: 50,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: 100
  },
  buttonGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  waitingCount: {
    textAlign: "center"
  },
  button: {
    background: theme.brand,
    color: "white",
    width: 280,
    height: 62,
    fontSize: 18,
    margin: "16px 0 22px 0",
    backgroundColor: "#00c3b5",

    "&:hover": {
      background: "#00a295"
    }
  },
  completedPopup: {
    display: "flex",
    flexDirection: "row",
    width: "439px",
    height: "84px",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#373737",
    padding: "16px 20px"
  },
  completedButton: {
    width: "146px",
    height: "42px",
    background: "#00C3B5",
    borderRadius: "4px",
    color: "#FFFFFF",
    fontFamily: "Avenir Next LT Pro",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "25px",
    "&:hover": {
      background: "#00ACA0"
    },
    "&:disabled": {
      background: "#C7C7C7"
    }
  },
  failedButton: {
    width: "146px",
    height: "42px",
    background: "#A70606",
    borderRadius: "4px",
    color: "#FFFFFF",
    fontFamily: "Avenir Next LT Pro",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "25px",
    "&:hover": {
      background: "#830a0a"
    }
  },
  buttonAmend: {
    backgroundColor: "#FF1F4A",
    minWidth: "45px !important",
    marginLeft: "5px",
    "&:hover": {
      backgroundColor: "#DF002A"
    }
  },
  text: {
    color: "#FFFFFF",
    width: "78px",
    fontSize: "14px",
    lineHeight: "19px",
    fontFamily: "Avenir Next LT Pro",
    fontStyle: "normal",
    fontWeight: 600
  },
  dialog: {
    padding: "40px"
  },
  title: {
    fontFamily: "Avenir Next LT Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "42px",
    lineHeight: "56px",
    textAlign: "center",
    letterSpacing: "1px",
    color: "#000000",
    marginBottom: "20px"
  },
  completed: {
    marginLeft: "6px",
    marginRight: "6px",
    color: "#00C3B5"
  },
  failed: {
    marginLeft: "6px",
    marginRight: "6px",
    color: "#A70606"
  },
  notif: {
    fontFamily: "Avenir Next LT Pro",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "16px",
    textAlign: "center",
    marginBottom: "24px"
  },
  description: {
    fontFamily: "Avenir Next LT Pro",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#282828",
    marginBottom: "24px"
  },
  completeDesc: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: 360,
    textAlign: "center",
    margin: "0 auto",
    marginBottom: 15
  },
  statusButtonGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginBottom: 20
  },
  covidButtonGroup: {
    height: "62px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  endCall: {
    width: "200px",
    height: "62px"
  },
  cancel: {
    width: "120px",
    height: "62px",
    border: "0.8px solid #000000",
    boxSizing: "border-box",
    borderRadius: "4px",
    marginLeft: "10px"
  },
  buttonComplete: {
    color: "#FFF",
    backgroundColor: "#00C3B5",
    "&:hover": {
      backgroundColor: "#00A395"
    }
  },
  positive: {
    width: "110px",
    height: "62px",
    border: "2px solid #B70000",
    boxSizing: "border-box",
    background: "#FFEEEE",
    borderRadius: "4px",
    "&.active": {
      background: "#B70000",
      color: "#FFFFFF"
    }
  },
  negative: {
    width: "110px",
    height: "62px",
    border: "2px solid #00C3B5",
    boxSizing: "border-box",
    background: "#EFFFFE",
    borderRadius: "4px",
    "&.active": {
      background: "#00C3B5",
      color: "#FFFFFF"
    }
  },
  invalid: {
    width: "110px",
    height: "62px",
    border: "2px solid #EB9A20",
    boxSizing: "border-box",
    background: "#FFF7EC",
    borderRadius: "4px",
    "&.active": {
      background: "#EB9A20",
      color: "#FFFFFF"
    }
  },
  completedBtn: {
    marginRight: "8px",
    background: "#C7C7C7",
    color: "#FFFFFF",
    "&:hover": {
      background: "#C7C7C7"
    },
    "&.active": {
      background: "#262626",
      color: "#FFFFFF"
    }
  },
  addInvoiceBtn: {
    width: "120px",
    height: "62px",
    backgroundColor: "#00C3B5",
    border: "none",
    fontWeight: "bold",
    boxSizing: "border-box",
    borderRadius: "4px",
    marginLeft: "10px",
    color: "#FFFFFF",
    "&:hover": {
      background: "#00BBAA"
    },
    "&:disabled": {
      background: "#C7C7C7"
    }
  },
  clientInfor: {
    fontWeight: "normal"
  },
  reasonSelector: {
    minWidth: "450px",
    marginBottom: "30px"
  },
  invoiceInput: {
    minHeight: "40px",
    minWidth: "100px",
    fontSize: "16px",
    textAlign: "center",
    marginTop: "15px",
    marginBottom: "15px"
  }
}));

export default function GetNextClientPage() {
  const classes = useStyles();
  const { isAuthReady } = useAppState();
  const [patientName, setPatientName] = useState(null);
  const [apptDateTime, setApptDateTime] = useState(null);
  const [contact, setContact] = useState(null);
  const [email, setEmail] = useState(null);
  const [bookingUuid, setBookingUuid] = useState("");
  const [reason, setReason] = useState(null);
  const [invoiceId, setInvoiceId] = useState("");
  const [invoiceError, setInvoiceError] = useState("");
  const [tableData, setTableData] = useState([]);
  const [buttonCancel, setButtonCancel] = useState(true);
  const [clickCompletedButton, setClickCompletedButton] = useState({
    open: false,
    completed: false
  });
  const [clickResendButton, setClickResendButton] = useState({
    open: false,
    completed: false
  });
  const [clickCancelButton, setClickCancelButton] = useState({
    open: false,
    completed: false
  });
  const [clickAddInvoiceButton, setAddInvoiceButton] = useState({
    open: false,
    completed: false
  });
  const [covidStatus, setCovidStatus] = useState<CovidStatus>(CovidStatus.None);

  const today = moment
    .utc()
    .utcOffset("+08:00")
    .format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const startRef = useRef(startDate);
  const endRef = useRef(endDate);
  startRef.current = startDate;
  endRef.current = endDate;

  const fetchData = async interval => {
    const response = await api.getClientList({
      from: startRef.current,
      to: endRef.current
    });
    setTableData(response?.jsonData);
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setReason(event.target.value);
    setButtonCancel(false);
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      fetchData(interval);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  if (!isAuthReady) {
    return null;
  }

  //filtering and search section
  const VISIBLE_FIELDS = [
    "name",
    "rating",
    "country",
    "dateCreated",
    "isAdmin"
  ];

  //handle complete visit and submission
  const completeCall = async () => {
    try {
      await api.completeSession({
        uuid: bookingUuid,
        status: "Disconnected",
        result: getCovidStatus()
      });

      const response = await api.getClientList({
        from: today,
        to: today
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  const resendSMS = async () => {
    try {
      const response = await api.resendConfirmation({
        uuid: bookingUuid,
        bookingDate: apptDateTime,
        sendSms: true,
        sendEmail: true,
        message:
          "Dear ${displayId}, your Tele-ART appointment with Speedoc on ${time} is confirmed. Please prepare a HSA-approved ART kit and be seated in a quiet area with stable internet connection. Visit this link for your appointment ${link}."
      });
      console.log(response?.ok);
      if (response?.ok === 1) {
        setClickResendButton({ open: false, completed: false });
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const cancelBooking = async () => {
    try {
      const response = await api.cancelSession({
        uuid: bookingUuid,
        reason: reason
      });
      console.log(response?.ok);
      if (response?.ok === 1) {
        setClickCancelButton({ open: false, completed: false });
        setReason(null);
      }
    } catch (e) {
      console.log("error", e);
      setReason(null);
    }
  };

  const addInvoice = async () => {
    try {
      const response = await api.addInvoice({
        uuid: bookingUuid,
        invoice_id: invoiceId
      });
      console.log(response?.ok);
      if (response?.ok === 1) {
        setAddInvoiceButton({ open: false, completed: false });
        setInvoiceId("");
      }
    } catch (e) {
      console.log("error", e);
      setInvoiceId("");
    }
  };

  const getCovidStatus = () => {
    switch (covidStatus) {
      case CovidStatus.Positive:
        return "Positive";
      case CovidStatus.Negative:
        return "Negative";
      case CovidStatus.Invalid:
        return "Invalid";
      default:
        return "None";
    }
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const handleComplete = (event, cellValues) => {
    setPatientName(cellValues?.row.Name);
    setBookingUuid(cellValues?.row.BookingUuid);
  };

  const handleResend = (event, cellValues) => {
    setPatientName(cellValues?.row.Name);
    setBookingUuid(cellValues?.row.BookingUuid);
    setContact(cellValues?.row.Contact);
    setEmail(cellValues?.row.Email);
    setApptDateTime(
      moment(cellValues?.row["Swab Date & Time"]).format("YYYY-MM-DD HH:mm")
    );
  };

  const handleCancel = (event, cellValues) => {
    setPatientName(cellValues?.row.Name);
    setBookingUuid(cellValues?.row.BookingUuid);
    setContact(cellValues?.row.Contact);
    setEmail(cellValues?.row.Email);
    setApptDateTime(
      moment(cellValues?.row["Swab Date & Time"]).format("YYYY-MM-DD HH:mm")
    );
  };

  const handleAddInvoice = (event, cellValues) => {
    setPatientName(cellValues?.row.Name);
    setBookingUuid(cellValues?.row.BookingUuid);
  };

  //define columns for datagrid
  const columns: GridColDef[] = [
    { field: "Name", headerName: "Name", width: 200 },
    { field: "NRIC", headerName: "NRIC", width: 130 },
    { field: "DOB", headerName: "DOB", width: 130 },
    { field: "Swab Date & Time", headerName: "Swab Date & Time", width: 170 },
    { field: "Swab Supervisor", headerName: "Swab Supervisor", width: 130 },
    { field: "Nationality", headerName: "Nationality", width: 130 },
    { field: "Organisation", headerName: "Organisation", width: 130 },
    { field: "Status", headerName: "Status", width: 130 },
    {
      field: "Action",
      renderCell: cellValues => {
        return (
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonComplete}
            onClick={event => {
              setClickCompletedButton({ open: true, completed: true });
              handleComplete(event, cellValues);
            }}
          >
            Complete
          </Button>
        );
      }
    },
    { field: "+ve/-ve", headerName: "Result", width: 130 },
    { field: "Purpose", headerName: "Purpose", width: 130 },
    { field: "LastModified", headerName: "Last Modified", width: 130 },
    { field: "LastPolled", headerName: "Last Active", width: 130 },
    { field: "Contact", headerName: "Contact", width: 130 },
    {
      field: "Manage Booking",
      width: 150,
      renderCell: cellValues => {
        return (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={event => {
                setClickResendButton({ open: true, completed: true });
                handleResend(event, cellValues);
              }}
            >
              Amend
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonAmend}
              onClick={event => {
                setClickCancelButton({ open: true, completed: true });
                handleCancel(event, cellValues);
              }}
            >
              X
            </Button>
          </div>
        );
      }
    },
    { field: "BookingUuid", headerName: "Link", width: 130 },
    {
      field: "Add Invoice",
      headerName: "Invoice ID",
      width: 130,
      renderCell: cellValues => {
        if (!cellValues.row.InvoiceId) {
          return (
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonComplete}
              onClick={event => {
                setAddInvoiceButton({ open: true, completed: false });
                handleAddInvoice(event, cellValues);
              }}
            >
              +
            </Button>
          );
        } else {
          return <span>{cellValues.row.InvoiceId}</span>;
        }
      }
    }
  ];

  return (
    <div className={classes.parent}>
      <div className={classes.header}>
        <img
          className={classes.headerImage}
          src="https://public.speedoc.com/strapi/logo-mobile.png?455486908.6"
        ></img>
        <div className={classes.rangePicker}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              renderInput={props => <TextField {...props} />}
              label="Start Date"
              inputFormat="yyyy-MM-dd"
              value={startDate}
              onChange={newValue => {
                setStartDate(moment(newValue).format("YYYY-MM-DD"));
                console.log(startDate);
              }}
            />
            <DatePicker
              renderInput={props => <TextField {...props} />}
              label="End Date"
              inputFormat="yyyy-MM-dd"
              value={endDate}
              onChange={newValue => {
                setEndDate(moment(newValue).format("YYYY-MM-DD"));
              }}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className={classes.body}>
        <DataGrid
          rows={tableData}
          columns={columns}
          getRowId={row => row.BookingUuid}
          components={{ Toolbar: GridToolbar }}
        />
        {clickCompletedButton.open && (
          <Dialog open={clickCompletedButton.open}>
            <div className={classes.dialog}>
              <div className={classes.title}>
                <span>{"Session"}</span>
                <span className={classes.completed}>"Completed"</span>
              </div>
              <div className={classes.notif}>
                <span>
                  {clickCompletedButton.completed ? (
                    <div className={classes.clientInfor}>
                      Submit ART Result for <b>{patientName}</b>:
                    </div>
                  ) : (
                    "This supervised ART session is invalid due to disconnection/obstruction of sight/faulty ART, etc."
                  )}
                </span>
              </div>
              <div className={classes.statusButtonGroup}>
                <Button
                  onClick={() => {
                    setCovidStatus(CovidStatus.Positive);
                  }}
                  className={`${classes.positive} ${
                    covidStatus === CovidStatus.Positive ? "active" : ""
                  }`}
                >
                  {"Positive"}
                </Button>
                <Button
                  onClick={() => {
                    setCovidStatus(CovidStatus.Negative);
                  }}
                  className={`${classes.negative} ${
                    covidStatus === CovidStatus.Negative ? "active" : ""
                  }`}
                >
                  {"Negative"}
                </Button>
                <Button
                  onClick={() => {
                    setCovidStatus(CovidStatus.Invalid);
                  }}
                  className={`${classes.invalid} ${
                    covidStatus === CovidStatus.Invalid ? "active" : ""
                  }`}
                >
                  {"Invalid"}
                </Button>
              </div>
              {clickCompletedButton.completed ? (
                <div className={classes.completeDesc}>
                  <div>
                    To confirm and end the call, click on the Submit Result
                    button below.
                  </div>
                  <div>
                    To go back to select again, click on the Cancel button.
                  </div>
                </div>
              ) : (
                <div className={classes.description}>
                  <div>
                    {
                      "To confirm and end the call, click on the End Call button below."
                    }
                  </div>
                  <div>
                    {"To go back to select again, click on the Cancel button."}
                  </div>
                </div>
              )}
              <div className={classes.covidButtonGroup}>
                <Button
                  onClick={() => {
                    if (clickCompletedButton.completed) {
                      if (covidStatus !== CovidStatus.None) {
                        setClickCompletedButton({
                          open: false,
                          completed: false
                        });
                        completeCall();
                      }
                    }
                  }}
                  className={`${clsx(
                    classes.endCall,
                    clickCompletedButton.completed
                      ? classes.completedBtn
                      : classes.failedBtn
                  )} ${covidStatus !== CovidStatus.None ? "active" : ""}`}
                  data-cy-disconnect
                >
                  {clickCompletedButton.completed
                    ? "Submit Result"
                    : "End Call"}
                </Button>
                <Button
                  onClick={() => {
                    setClickCompletedButton({ open: false, completed: false });
                  }}
                  className={classes.cancel}
                >
                  {"Cancel"}
                </Button>
              </div>
            </div>
          </Dialog>
        )}

        {clickResendButton.open && (
          <Dialog open={clickResendButton.open}>
            <div className={classes.dialog}>
              <div className={classes.title}>
                <span>Manage Booking</span>
              </div>
              <div className={classes.notif}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={props => <TextField {...props} />}
                    label="Appointment Date & Time"
                    inputFormat="yyyy-MM-dd HH:mm"
                    value={apptDateTime}
                    onChange={newValue => {
                      setApptDateTime(
                        moment(newValue).format("YYYY-MM-DD HH:mm")
                      );
                    }}
                  />
                </LocalizationProvider>
                <br />
                <br />
                <span>
                  Amend this to re-schedule the patient's appointment.
                </span>
                <br />
                <br />
                <span>
                  {clickResendButton.completed ? (
                    <div className={classes.clientInfor}>
                      <br />
                      <span>
                        Please verify patient's contact details prior to
                        re-sending.
                      </span>
                      <br />
                      <br />
                      <span>Name: {patientName}</span>
                      <br />
                      <span>Contact: {contact}</span>
                      <br />
                      <span>Email: {email}</span>
                      <br />
                    </div>
                  ) : (
                    "This supervised ART session is invalid due to disconnection/obstruction of sight/faulty ART, etc."
                  )}
                </span>
              </div>
              <div className={classes.covidButtonGroup}>
                <Button
                  onClick={() => {
                    resendSMS();
                  }}
                  className={`${clsx(
                    classes.endCall,
                    classes.completedButton
                  )} ${covidStatus !== CovidStatus.None ? "active" : ""}`}
                  data-cy-disconnect
                >
                  {"Confirm"}
                </Button>
                <Button
                  onClick={() => {
                    setClickResendButton({ open: false, completed: false });
                  }}
                  className={classes.cancel}
                >
                  {"Cancel"}
                </Button>
              </div>
            </div>
          </Dialog>
        )}

        {clickCancelButton.open && (
          <Dialog open={clickCancelButton.open}>
            <div className={classes.dialog}>
              <div className={classes.title}>
                <span>Cancel Booking</span>
              </div>
              <div className={classes.notif}>
                <span>
                  Please confirm that you are cancelling the right visit.
                </span>
                <br />
                <br />
                <span>
                  {clickCancelButton.completed ? (
                    <div className={classes.clientInfor}>
                      <br />
                      <span>Booking Date: {apptDateTime}</span>
                      <br />
                      <span>Name: {patientName}</span>
                      <br />
                      <span>Contact: {contact}</span>
                      <br />
                      <span>Email: {email}</span>
                      <br />
                    </div>
                  ) : (
                    "This supervised ART session is invalid due to disconnection/obstruction of sight/faulty ART, etc."
                  )}
                </span>
              </div>
              <div>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small">Reason</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    className={classes.reasonSelector}
                    value={reason}
                    label="Reason for Cancellation"
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="[User] Incorrect Booking">
                      User made an Incorrect Booking
                    </MenuItem>
                    <MenuItem value="[User] Double Booking">
                      User made a Double Booking
                    </MenuItem>
                    <MenuItem value="[User] ART No Longer Required">
                      User no longer needs a Tele-ART
                    </MenuItem>
                    <MenuItem value="[User] Reschedule">
                      User Re-scheduled to Other Date
                    </MenuItem>
                    <MenuItem value="[User] Rebook to Home Testing">
                      User Rebooked to Home Testing
                    </MenuItem>
                    <MenuItem value="[User] Technical Issues">
                      User experienced Technical Issues
                    </MenuItem>
                    <MenuItem value="[User] Long Wait">
                      User experienced Long Waiting Time
                    </MenuItem>
                    <MenuItem value="[User] Found other Provider">
                      User booked with other Provider
                    </MenuItem>
                    <MenuItem value="[SD] Incorrect Booking">
                      Speedoc made an Incorrect Booking
                    </MenuItem>
                    <MenuItem value="[SD] Double Booking">
                      Speedoc made a Double Booking
                    </MenuItem>
                    <MenuItem value="[SD] Technical Issues">
                      Speedoc / Provider faced Technical Issues
                    </MenuItem>
                    <MenuItem value="[Gov] ART No longer Required">
                      [Gov Policy Change] Tele-ART No Longer Required
                    </MenuItem>
                    <MenuItem value="[Dev] Internal Test Booking">
                      [Dev] Internal Testing
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className={classes.covidButtonGroup}>
                <Button
                  disabled={buttonCancel}
                  onClick={() => {
                    cancelBooking();
                    setButtonCancel(true);
                  }}
                  className={`${clsx(
                    classes.endCall,
                    classes.completedButton
                  )} ${covidStatus !== CovidStatus.None ? "active" : ""}`}
                  data-cy-disconnect
                >
                  {"Confirm"}
                </Button>
                <Button
                  onClick={() => {
                    setClickCancelButton({ open: false, completed: false });
                    setButtonCancel(true);
                  }}
                  className={classes.cancel}
                >
                  {"Cancel"}
                </Button>
              </div>
            </div>
          </Dialog>
        )}

        {clickAddInvoiceButton.open && (
          <Dialog open={clickAddInvoiceButton.open}>
            <div className={classes.dialog}>
              <div className={classes.title}>
                <span>{"Add Invoice ID"}</span>
              </div>
              <div className={classes.completeDesc}>
                <div>Enter Invoice Number:</div>
                <div>
                  <input
                    className={classes.invoiceInput}
                    type="text"
                    value={invoiceId}
                    onChange={event => {
                      setInvoiceId(event.target.value);
                      if (event.target.value.length !== 13) {
                        setInvoiceError(
                          "Note: Please enter the full Avixo Invoice Number."
                        );
                      } else {
                        setAddInvoiceButton({ open: true, completed: true });
                        setInvoiceError(
                          "Press Submit to tag this invoice to the patient."
                        );
                      }
                    }}
                  />
                  <br />
                  <span className={classes.errorPlaceholder}>
                    {invoiceError}
                  </span>
                </div>
              </div>
              <div className={classes.covidButtonGroup}>
                <Button
                  disabled={!clickAddInvoiceButton.completed}
                  onClick={() => {
                    setAddInvoiceButton({ open: false, completed: false });
                    addInvoice();
                  }}
                  className={classes.addInvoiceBtn}
                >
                  {"Submit"}
                </Button>
                <Button
                  onClick={() => {
                    setAddInvoiceButton({ open: false, completed: false });
                  }}
                  className={classes.cancel}
                >
                  {"Cancel"}
                </Button>
              </div>
            </div>
          </Dialog>
        )}
      </div>
    </div>
  );
}
