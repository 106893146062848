import React from "react";
import {
  FormControl,
  MenuItem,
  Typography,
  Select,
  Theme
} from "@material-ui/core";
import { useAppState } from "../../../state";
import useDevices from "../../../hooks/useDevices/useDevices";
import {
  createTheme,
  makeStyles,
  ThemeProvider
} from "@material-ui/core/styles";
import SpeakerIcon from "../../../icons/SpeakerIcon";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const theme = createTheme({
  overrides: {
    MuiListItem: {
      root: {
        "&.Mui-selected": {
          backgroundColor: "#E1F3FE"
        },
        "&:hover": {
          backgroundColor: "#F5F5F5!important"
        }
      }
    }
  }
});

const useStyles = makeStyles((theme: Theme) => ({
  boxAudio: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #C7C7C7",
    borderRadius: "4px",
    paddingLeft: "0px",
    paddingTop: "5px",
    marginBottom: "15px",
    fontFamily: "Avenir Next LT Pro",
    position: "relative",
    "& svg": {
      position: "absolute",
      left: "5px",
      top: "20px"
    },
    "& div[class*='selectMenu']": {
      paddingLeft: "34px"
    }
  },
  inputSelect: {
    width: "100%",
    position: "relative",
    "& fieldset": {
      border: "none"
    },
    "& svg": {
      right: "10px !important",
      top: "10px !important",
      left: "unset",
      fill: "#282828",
      width: "30px",
      height: "30px"
    },
    "& .MuiPaper-root": {
      maxWidth: "300px !important",
      "& .MuiPopover-paper": {
        maxWidth: "300px !important",
        "& .MuiPaper-elevation8": {
          "& .MuiPaper-rounded": {
            maxWidth: "300px !important"
          }
        }
      }
    }
  },
  textTitle: {
    fontSize: "10px",
    marginLeft: "34px",
    marginBottom: "-7px",
    color: "#828282"
  },
  inputSelect1: {
    padding: "16px 16px !important",
    fontSize: "14px"
  },
  textNoLocal: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  textPadding: {
    marginLeft: "12px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingLeft: "24px"
  },
  root: {
    "& .MuiPaper-root": {
      maxWidth: "300px !important"
    }
  },
  selectOptions: {
    "& .MuiListItem-root": {
      borderRadius: 0
    },
    backgroundColor: "#fff",
    padding: "0 !important",
    margin: "0 !important",
    borderRight: "1px solid #C7C7C7",
    borderLeft: "1px solid #C7C7C7",
    borderBottom: "1px solid #C7C7C7",
    boxShadow: "none"
  }
}));

export default function AudioOutputList(props: { hidePreview: any }) {
  const hidePreview = props.hidePreview;
  const { audioOutputDevices } = useDevices();
  const { activeSinkId, setActiveSinkId } = useAppState();
  const activeOutputLabel = audioOutputDevices.find(
    device => device.deviceId === activeSinkId
  )?.label;
  const classes = useStyles();

  const MenuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        maxHeight: "auto",
        width: 240
      }
    }
  };
  MenuProps.PaperProps.className = classes.selectOptions;

  return (
    <div className={hidePreview ? classes.boxAudio : ""}>
      {hidePreview ? <SpeakerIcon /> : ""}
      {audioOutputDevices.length > 1 ? (
        <div className={hidePreview ? classes.inputSelect : ""}>
          <ThemeProvider theme={theme}>
            <FormControl fullWidth>
              {hidePreview ? (
                <Typography
                  className={classes.textTitle}
                  variant="subtitle2"
                  gutterBottom
                >
                  Speaker
                </Typography>
              ) : (
                <Typography variant="subtitle2" gutterBottom>
                  Audio Output
                </Typography>
              )}

              <Select
                onChange={e => setActiveSinkId(e.target.value as string)}
                value={activeSinkId}
                variant="outlined"
                MenuProps={MenuProps}
                IconComponent={hidePreview ? KeyboardArrowDownIcon : undefined}
              >
                {audioOutputDevices.map(device => (
                  <MenuItem
                    className={classes.inputSelect1}
                    value={device.deviceId}
                    key={device.deviceId}
                  >
                    {device.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ThemeProvider>
        </div>
      ) : (
        <div>
          {hidePreview ? (
            <Typography
              className={classes.textTitle}
              variant="subtitle2"
              gutterBottom
            >
              Speaker
            </Typography>
          ) : (
            <Typography variant="subtitle2" gutterBottom>
              Audio Output
            </Typography>
          )}
          <Typography
            className={hidePreview ? classes.textPadding : classes.textNoLocal}
          >
            {activeOutputLabel || "System Default Audio Output"}
          </Typography>
        </div>
      )}
    </div>
  );
}
