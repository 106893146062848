import React, { ChangeEvent, useState, FormEvent } from "react";
import { useAppState } from "../../state";
import Button from "@material-ui/core/Button";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Grid from "@material-ui/core/Grid";
import { ReactComponent as GoogleLogo } from "./google-logo.svg";
import { Theme } from "@material-ui/core";
import IntroContainer from "../IntroContainer/IntroContainer";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import * as api from "../../utils/service";
import { setAuthenticationToken } from "../../utils/authentication";

const useStyles = makeStyles((theme: Theme) => ({
  googleButton: {
    background: "white",
    color: "rgb(0, 94, 166)",
    borderRadius: "4px",
    border: "2px solid rgb(2, 122, 197)",
    margin: "1.8em 0 0.7em",
    textTransform: "none",
    boxShadow: "none",
    padding: "0.3em 1em",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    "&:hover": {
      background: "white",
      boxShadow: "none"
    }
  },
  errorMessage: {
    color: "red",
    display: "flex",
    alignItems: "center",
    margin: "1em 0 0.2em",
    "& svg": {
      marginRight: "0.4em"
    }
  },
  gutterBottom: {
    marginBottom: "1em"
  },
  passcodeContainer: {
    minHeight: "50px"
  },
  submitButton: {
    color: "#fff !important",
    padding: "13px 30px",
    fontSize: "24px",
    marginTop: "53px",
    fontWeight: 600,
    fontFamily: "Avenir Next LT Pro",
    letterSpacing: "1.5px",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    background: "#000 !important"
  },
  fullWidth: {
    minWidth: "100%"
  },
  flexEnd: {
    justifyContent: "flex-end",
    display: "flex"
  },
  passcodeContainer1: {
    marginTop: "15px"
  },
  textBold: {
    fontWeight: "bold"
  },
  label: {
    fontSize: "14px"
  },
  textDirection: {
    borderBottom: "1px solid #282828",
    color: "#282828",
    marginTop: "10px",
    display: "block",
    width: "fit-content",
    fontWeight: 400,
    fontFamily: "Avenir Next LT Pro",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px"
    }
  }
}));

export default function LoginPage() {
  const classes = useStyles();
  const { signIn, isAuthReady } = useAppState();
  const history = useHistory();
  let [userName, setUserName] = useState("");
  let [password, setPassword] = useState("");
  const [authError, setAuthError] = useState<Error | null>(null);

  const isAuthEnabled = Boolean(process.env.REACT_APP_SET_AUTH);

  const login = async () => {
    setAuthError(null);
    let rp = await api.login({ username: userName, password });
    if (rp && rp.supervisor?.jwt) {
      setAuthenticationToken(rp.supervisor?.jwt);
      signIn?.(rp)
        .then(trp => {
          //history.replace(location?.state?.from || { pathname: "/" });
          history.replace({ pathname: "/next-client" });
        })
        .catch(err => setAuthError(err));
    } else {
      setAuthError(new Error("Username or password invalid!"));
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login();
  };

  if (!isAuthEnabled) history.replace("/");

  if (!isAuthReady) return null;

  return (
    <IntroContainer normal={true}>
      {process.env.REACT_APP_SET_AUTH === "firebase" && (
        <>
          <Typography variant="h5" className={classes.gutterBottom}>
            Sign in to join a room
          </Typography>
          <Typography variant="body1">
            Sign in using your Twilio Google Account
          </Typography>
          <Button
            variant="contained"
            className={classes.googleButton}
            onClick={login}
            startIcon={<GoogleLogo />}
          >
            Sign in with Google
          </Button>
        </>
      )}

      {(process.env.REACT_APP_SET_AUTH === "passcode" ||
        process.env.REACT_APP_SET_AUTH === "pwd") && (
        <>
          <Typography
            variant="h6"
            className={`${classes.label} ${classes.gutterBottom}`}
          >
            Please enter your ID and password
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container justifyContent="space-between">
              <Grid item xs={12}>
                <div className={classes.passcodeContainer}>
                  <TextField
                    className={classes.fullWidth}
                    id="input-username"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setUserName(e.target.value)
                    }
                    type="text"
                    variant="outlined"
                    size="medium"
                    placeholder="ID"
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={`${classes.passcodeContainer1}`}>
                  <TextField
                    id="input-passcode"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setPassword(e.target.value)
                    }
                    type="password"
                    variant="outlined"
                    size="medium"
                    color="secondary"
                    placeholder="Password"
                    className={classes.fullWidth}
                  />
                  <div>
                    {authError && (
                      <Typography
                        variant="caption"
                        className={classes.errorMessage}
                      >
                        <ErrorOutlineIcon />
                        {authError.message}
                      </Typography>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <label className={`${classes.textDirection}`}>
                  Forgot your password?
                </label>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!password.length || !userName.length}
                className={`${classes.submitButton} ${classes.fullWidth}`}
              >
                Login
              </Button>
            </Grid>
          </form>
        </>
      )}
    </IntroContainer>
  );
}
