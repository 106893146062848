import React, { useState, useCallback, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { ReactComponent as Logo } from "../../../images/Logo.svg";
import Background2 from "../../../images/Background2.svg";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import * as api from "../../../utils/service";
import paths from "../../../utils/paths";
import Spinner from "../../Spinner/Spinner";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center"
  },
  wrap: {
    maxWidth: 672,
    display: "flex",
    flexDirection: "column"
  },
  background: {
    backgroundImage: `url(${Background2})`,
    width: "100%",
    height: 900,
    position: "absolute",
    zIndex: -1,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    top: 180
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    margin: "62px 0 32px 0"
  },
  card: {
    borderRadius: 4,
    width: "90%",
    maxWidth: 672,
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: "white",
    padding: 40,
    margin: "auto"
  },
  bookingCard: {
    width: "90%",
    maxWidth: 421,
    height: 111,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  bookingRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    minHeight: 55,
    borderBottom: "1px solid #f3f0f0"
  },
  bookingItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 600,
    fontStyle: "normal",
    fontSize: 14
  },
  bookingItemName: {
    width: 130
  },
  content: {
    margin: 0,
    paddingLeft: 20
  },
  title: {
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 42,
    lineHeight: "56px",
    textAlign: "center",
    marginBottom: 29
  },
  subtitle: {
    fontFamily: "Avenir Next LT Pro",
    fontStyle: "normal",
    fontSize: 24,
    fontWeight: 600,
    textAlign: "center",
    marginBottom: 24
  },
  confirmationText: {
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: 16,
    textAlign: "center",
    margin: "16px 0 16px 0",
    minWidth: 156
  },
  another: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: 20
  },
  button: {
    background: theme.brand,
    color: "white",
    width: 280,
    height: 62,
    margin: "16px 0 22px 0",
    backgroundColor: "black",

    "&:hover": {
      background: "#696969"
    }
  }
}));

interface MissedPageProps {
  date?: string;
  time?: string;
}

export default function MissedPage({ date, time }: MissedPageProps) {
  const classes = useStyles();
  const history = useHistory();
  const { bookingUuid } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [scheduleDetail, setScheduleDetail] = useState({
    date: "",
    startTime: "",
    endTime: ""
  });

  const fetchClient = useCallback(async () => {
    const { client } = await api.getClientByUuid({ uuid: bookingUuid });
    if (client?.status === "Missed") {
      setScheduleDetail({
        date: moment
          .utc(client.bookingDate)
          .utcOffset("+08:00")
          .format("dddd, DD MMM YYYY"),
        startTime: moment
          .utc(client.bookingDate)
          .utcOffset("+08:00")
          .format("HH:mm"),
        endTime: moment
          .utc(client.bookingDate)
          .utcOffset("+08:00")
          .add("30", "m")
          .format("HH:mm")
      });
      setIsLoading(false);
    } else history.push(paths(client));
  }, [history, bookingUuid]);

  useEffect(() => {
    fetchClient().catch(console.log);
  }, [fetchClient]);

  if (isLoading) return <Spinner />;

  return (
    <div className={classes.container}>
      <div className={classes.background} />
      <div className={classes.wrap}>
        <div className={classes.logo}>
          <Logo />
        </div>
        <div className={classes.title}>Tele-ART</div>
        <div className={classes.card}>
          <div className={classes.subtitle}>
            You missed the following appointment:
          </div>
          <div className={classes.bookingCard}>
            <div className={classes.bookingRow}>
              <div
                className={`${classes.bookingItem} ${classes.bookingItemName}`}
              >
                Date:
              </div>
              <div className={classes.bookingItem}>{scheduleDetail.date}</div>
            </div>
            <div className={classes.bookingRow}>
              <div
                className={`${classes.bookingItem} ${classes.bookingItemName}`}
              >
                Time:
              </div>
              <div className={classes.bookingItem}>
                {scheduleDetail.startTime} - {scheduleDetail.endTime}
              </div>
            </div>
          </div>
          <div className={classes.confirmationText}>
            The above appointment has been <b>cancelled</b>.
          </div>
          <div className={classes.another}>
            <b>Please make another booking.</b>
          </div>
        </div>
      </div>
    </div>
  );
}
