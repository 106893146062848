import React from "react";
import Button from "@material-ui/core/Button";
import WarningIcon from "../../../icons/NoteIcon";
import useChatContext from "../../../hooks/useChatContext/useChatContext";
import NoteIcon1 from "../../../icons/NoteIcon1";

export default function ToggleWarningButton() {
  const { isRFWindowOpen, setIsRFWindowOpen } = useChatContext();
  const toggleWarningButton = () => {
    setIsRFWindowOpen(!isRFWindowOpen);
  };

  return (
    <Button
      onClick={toggleWarningButton}
      startIcon={!isRFWindowOpen ? <WarningIcon /> : <NoteIcon1 />}
    ></Button>
  );
}
