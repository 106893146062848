import { useEffect } from "react";

export default function useOnClickOutside(
  ref: React.MutableRefObject<undefined>,
  onClickOutside: () => void
) {
  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    };
    onClickOutside &&
      document.addEventListener("mousedown", handleClickOutside);
    return () => {
      onClickOutside &&
        document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside]);
}
