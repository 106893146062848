import React, { ChangeEvent, useEffect, useState, useCallback } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import LogoBlackIcon from "../../../icons/LogoBlackIcon";
import BgKitIcon from "../../../icons/BgKitIcon";
import * as api from "../../../utils/service";
import { useHistory, useParams } from "react-router-dom";
import Help from "../../Help/Help";
import paths from "../../../utils/paths";
import Spinner from "../../Spinner/Spinner";

const useStyles = makeStyles((theme: Theme) => ({
  googleButton: {
    background: "white",
    color: "rgb(0, 94, 166)",
    borderRadius: "4px",
    border: "2px solid rgb(2, 122, 197)",
    margin: "1.8em 0 0.7em",
    textTransform: "none",
    boxShadow: "none",
    padding: "0.3em 1em",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    "&:hover": {
      background: "white",
      boxShadow: "none"
    }
  },
  errorMessage: {
    color: "red",
    display: "flex",
    alignItems: "center",
    margin: "1em 0 0.2em",
    "& svg": {
      marginRight: "0.4em",
      transform: "rotate(180deg)"
    }
  },
  gutterBottom: {
    marginBottom: "1em"
  },
  passcodeContainer: {
    minHeight: "50px",
    position: "relative"
  },
  submitButton: {
    color: "#fff !important",
    padding: "13px 30px",
    fontSize: "24px",
    marginTop: "25px",
    fontWeight: 600,
    fontFamily: "Avenir Next LT Pro",
    letterSpacing: "1.5px",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    background: "#000 !important"
  },
  fullWidth: {
    minWidth: "100%"
  },
  flexEnd: {
    justifyContent: "flex-end",
    display: "flex"
  },
  passcodeContainer1: {
    marginTop: "15px",
    position: "relative"
  },
  textBold: {
    fontWeight: "bold"
  },
  label: {
    fontSize: "14px"
  },
  textDirection: {
    borderBottom: "1px solid #282828",
    color: "#282828",
    marginTop: "10px",
    display: "block",
    width: "fit-content",
    fontWeight: 400,
    fontFamily: "Avenir Next LT Pro",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px"
    }
  },
  boxKit: {
    width: "485px",
    borderRadius: "4px",
    boxShadow: "0px 2px 4px 0px rgba(40, 42, 43, 0.3)",
    position: "relative",
    margin: "0 auto",
    padding: "40px",
    background: "#fff",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      height: "auto",
      width: "calc(100% - 40px)",
      maxWidth: "400px"
    },
    "& svg": {
      position: "absolute",
      right: "15px",
      top: "15px",
      transform: "rotate(180deg)"
    }
  },
  kit: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Avenir Next LT Pro",
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start"
    }
  },
  topKit: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center"
  },
  contentKit: {
    position: "absolute",
    "& input": {
      position: "relative"
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "30px"
    }
  },
  titleText: {
    fontSize: "42px",
    fontWeight: 500,
    margin: "50px 0",
    fontFamily: "Avenir Next LT Pro"
  },
  parText: {
    fontSize: "24px",
    marginBottom: "15px",
    fontWeight: 600,
    fontFamily: "Avenir Next LT Pro"
  },
  tooltips: {
    fontSize: "12px",
    padding: "10px"
  },
  help: {
    position: "absolute",
    top: "2px",
    right: "-210px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      right: "-150px"
    },
    [theme.breakpoints.down("xs")]: {
      right: "20px"
    }
  }
}));

export default function UpdateTestKit() {
  const classes = useStyles();
  const history = useHistory();
  const [brand, setBrand] = useState("");
  const [lotNumber, setLotNumber] = useState("");
  const { bookingUuid } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const updateTestKit = async e => {
    e.preventDefault();
    try {
      const response = await api.updateTestKit({
        uuid: bookingUuid,
        brand: brand,
        lotNumber: lotNumber
      });
      if (response?.ok === 1) {
        const { result: updatedClient } = await api.updateClientStatus({
          uuid: bookingUuid,
          status: "VideoCheck"
        });
        history.push(paths(updatedClient));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchClient = useCallback(async () => {
    const { client } = await api.getClientByUuid({ uuid: bookingUuid });
    if (client?.status === "ARTKit") setIsLoading(false);
    else history.push(paths(client));
  }, [history, bookingUuid]);

  useEffect(() => {
    fetchClient().catch(console.log);
  }, [fetchClient]);

  if (isLoading) return <Spinner />;

  return (
    <div className={classes.kit}>
      <BgKitIcon />
      <div className={classes.contentKit}>
        <div className={classes.topKit}>
          <LogoBlackIcon />
          <div className={classes.help}>
            <Help />
          </div>
          <Typography variant="h2" className={classes.titleText}>
            Tele-ART
          </Typography>
        </div>
        <div className={classes.boxKit}>
          <Typography variant="h6" className={classes.parText}>
            Please fill in your ART Kit details
          </Typography>
          <form onSubmit={updateTestKit}>
            <Grid container justifyContent="space-between">
              <Grid item xs={12}>
                <div className={classes.passcodeContainer}>
                  <TextField
                    className={classes.fullWidth}
                    id="input-brand"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setBrand(e.target.value)
                    }
                    type="text"
                    variant="outlined"
                    size="medium"
                    placeholder="Brand of kit"
                    required
                  />
                  <Tooltip
                    title="*Example: Abbott, SD Biosensor, Flowflex"
                    placement={"right"}
                  >
                    <ErrorOutlineIcon />
                  </Tooltip>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.passcodeContainer1}>
                  <TextField
                    id="input-lotNumber"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setLotNumber(e.target.value)
                    }
                    type="text"
                    variant="outlined"
                    size="medium"
                    placeholder="Lot number"
                    className={classes.fullWidth}
                    required
                  />
                  <ErrorOutlineIcon />
                  <div></div>
                </div>
              </Grid>
              <Grid item xs={6}></Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!brand.length || !lotNumber.length}
                className={`${classes.submitButton} ${classes.fullWidth}`}
              >
                Continue
              </Button>
            </Grid>
          </form>
        </div>
      </div>
    </div>
  );
}
