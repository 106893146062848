import React, { SVGProps } from "react";

export default function BgLogin(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1015"
      height="582"
      viewBox="0 0 1015 582"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M985.346 901.488L44.8182 1103.11C21.9184 1108.12 0 1091.41 0 1069.13V236.486C0 220.055 11.7771 205.853 28.7883 202.511L969.316 0.894124C992.216 -4.11846 1014.13 12.5901 1014.13 34.8683V867.514C1014.13 883.666 1002.36 897.868 985.346 901.488Z"
        fill="url(#paint0_linear_62_1818)"
        fillOpacity="0.7"
      />
      <defs>
        <linearGradient
          id="paint0_linear_62_1818"
          x1="-218.613"
          y1="812.471"
          x2="1103.32"
          y2="-157.744"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7FAF7" />
          <stop offset="1" stopColor="#A9B6E8" />
        </linearGradient>
      </defs>
    </svg>
  );
}
