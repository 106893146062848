import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { ReactComponent as Logo } from "../../../images/Logo.svg";
import Background1 from "../../../images/Background1.svg";
import paths from "../../../utils/paths";
import * as api from "../../../utils/service";
import Spinner from "../../Spinner/Spinner";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    maxWidth: 1104,
    margin: "0 auto"
  },
  wrap: {
    width: "90%",
    maxWidth: 538,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    margin: "auto"
  },
  background: {
    backgroundImage: `url(${Background1})`,
    width: "100%",
    maxWidth: 1104,
    height: 950,
    position: "absolute",
    zIndex: -1,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    margin: "auto"
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    margin: "62px 0 32px 0"
  },
  content: {
    margin: 0,
    paddingLeft: 20,
    fontSize: 13
  },
  subtitle1: {
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 600,
    fontStyle: "normal",
    fontSize: 24,
    lineHeight: "30px",
    textAlign: "center",
    marginBottom: 21
  },
  buttonGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 47
  },
  specialNote: {
    fontSize: "14px",
    marginbottom: "15px"
  },
  button: {
    background: theme.brand,
    color: "white",
    width: 280,
    height: 62,
    margin: "16px 0 22px 0",
    backgroundColor: "black",

    "&:hover": {
      background: "#696969"
    }
  },
  submitButton: {
    color: "#fff !important",
    padding: "13px 30px",
    fontSize: "24px",
    fontWeight: 600,
    fontFamily: "Avenir Next LT Pro",
    letterSpacing: "1.5px",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  btnOpen: {
    background: "#00C3B5 !important",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "24px",
    fontFamily: "Avenir Next LT Pro",
    padding: "20px 40px",
    marginBottom: "48px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 11px"
    }
  },
  preNote: {
    fontSize: "12px"
  },
  redHighlight: {
    color: "#d54c4c",
    fontSize: "16px",
    fontWeight: "600"
  },
  subtitle1: {
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 600,
    fontStyle: "normal",
    fontSize: 24,
    lineHeight: "16px",
    textAlign: "center",
    marginBottom: 21
  },
  subtitle2: {
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 600,
    fontStyle: "normal",
    fontSize: 24,
    lineHeight: "12px",
    textAlign: "center",
    marginBottom: 21
  }
}));

export default function CompletedScreen() {
  const classes = useStyles();
  const history = useHistory();
  const { bookingUuid } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const fetchClient = useCallback(async () => {
    const { client } = await api.getClientByUuid({ uuid: bookingUuid });
    if (client?.status === "Completed") setIsLoading(false);
    else history.push(paths(client));
  }, [history, bookingUuid]);

  useEffect(() => {
    fetchClient().catch(console.log);
  }, [fetchClient]);

  const registration = () => {
    window.location.href =
      "https://php.healthhub.sg/HealthDeclaration?c=20M0266&df=true";
  };

  const goToSpeedoc = () => {
    window.location.href = "https://speedoc.com/sg";
  };

  if (isLoading) return <Spinner />;

  return (
    <div className={classes.container}>
      <div className={classes.background} />
      <div className={classes.wrap}>
        <div className={classes.logo}>
          <Logo />
        </div>
        <div className={classes.subtitle1}>Thank you for attending the</div>
        <div className={classes.subtitle2}>
          Supervised Tele&#8209;ART session.
        </div>
        <div className={classes.preNote}>Please Note:</div>
        <div className={classes.specialNote}>
          For your results to be available on{" "}
          <span className={classes.redHighlight}>TraceTogether</span>, please
          complete the following registration form.
        </div>
        <div>
          <div className={classes.buttonGroup}>
            <Button
              variant="contained"
              onClick={registration}
              className={`${classes.submitButton} ${classes.btnOpen}`}
            >
              Open Registration Form
            </Button>
          </div>
          <ul className={classes.content}>
            <li>
              The validated ART results will be sent to you within 4 hours via
              SMS/email.
            </li>
            <li>
              If you tested negative, there is no further action required.
            </li>
            <li>
              {" "}
              If you tested positive, follow this guide{" "}
              <a
                href="https://www.moh.gov.sg/docs/librariesprovider5/art---self-test/simple-guide-to-art-self-test-kits-(jul-21---web-version).pdf"
                target="_blank"
              >
                here
              </a>
              .
            </li>
            <li>
              If you are a VTL traveller, follow the instructions given by MOH
              in your Testing Notice.
            </li>
          </ul>
        </div>
        <div className={classes.buttonGroup}>
          <Button
            className={classes.button}
            data-cy-disconnect
            onClick={goToSpeedoc}
          >
            Visit Speedoc
          </Button>
        </div>
      </div>
    </div>
  );
}
