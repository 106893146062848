import * as api from "./api";

const AUTHENTICATION_STORAGE_KEY = "xapiKey";

export const getAuthenticationToken = async () => {
  try {
    const value = api.getCookie(AUTHENTICATION_STORAGE_KEY);
    if (value !== null && value !== "null" && value !== "undefined") {
      return value;
    }
    return null;
  } catch (e) {
    console.log("Error read token into local");
    return null;
  }
};

export const setAuthenticationToken = async (token) => {
  try {
    api.setCookie(AUTHENTICATION_STORAGE_KEY, token);
  } catch (e) {
    console.log("Error save token into local", e);
  }
};
