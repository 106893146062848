import React, { useState, useCallback, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { ReactComponent as Logo } from "../../../images/Logo.svg";
import Background2 from "../../../images/Background2.svg";
import { useParams, useHistory } from "react-router-dom";
import * as api from "../../../utils/service";
import paths from "../../../utils/paths";
import Spinner from "../../Spinner/Spinner";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center"
  },
  wrap: {
    maxWidth: 672,
    display: "flex",
    flexDirection: "column"
  },
  background: {
    backgroundImage: `url(${Background2})`,
    width: "100%",
    height: 900,
    position: "absolute",
    zIndex: -1,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    top: 180
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    margin: "62px 0 32px 0"
  },
  card: {
    borderRadius: 4,
    width: "90%",
    maxWidth: 672,
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: "white",
    padding: 40,
    margin: "auto",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  bookingCard: {
    width: "90%",
    maxWidth: 421,
    height: 111,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  bookingRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    minHeight: 55,
    borderBottom: "1px solid #f3f0f0"
  },
  bookingItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 600,
    fontStyle: "normal",
    fontSize: 14
  },
  bookingItemName: {
    width: 130
  },
  content: {
    margin: 0,
    paddingLeft: 20
  },
  title: {
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 42,
    lineHeight: "56px",
    textAlign: "center",
    marginBottom: 29
  },
  subtitle: {
    fontFamily: "Avenir Next LT Pro",
    fontStyle: "normal",
    fontSize: 24,
    fontWeight: 600,
    textAlign: "center",
    marginBottom: 24
  },
  bodyText: {
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 17,
    textAlign: "center",
    minWidth: 156
  },
  confirmationText: {
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 16,
    textAlign: "center",
    margin: "16px 0 16px 0",
    minWidth: 156
  },
  another: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: 20
  },
  buttonGroup: {
    margin: "auto",
    marginTop: 30
  },
  button: {
    background: theme.brand,
    color: "white",
    width: 280,
    height: 62,
    margin: "auto",
    backgroundColor: "#2D2D2D",

    "&:hover": {
      background: "#696969"
    }
  }
}));

export default function Failed() {
  const classes = useStyles();
  const { bookingUuid } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const fetchClient = useCallback(async () => {
    const { client } = await api.getClientByUuid({ uuid: bookingUuid });
    if (client?.status === "Failed") setIsLoading(false);
    else history.push(paths(client));
  }, [history, bookingUuid]);

  useEffect(() => {
    fetchClient().catch(console.log);
  }, [fetchClient]);

  const goToWaitingRoom = async () => {
    try {
      const { result: updatedClient } = await api.updateClientStatus({
        uuid: bookingUuid,
        status: "Waiting"
      });
      history.push(paths(updatedClient));
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) return <Spinner />;

  return (
    <div className={classes.container}>
      <div className={classes.background} />
      <div className={classes.wrap}>
        <div className={classes.logo}>
          <Logo />
        </div>
        <div className={classes.title}>Tele-ART</div>
        <div className={classes.card}>
          <div className={classes.subtitle}>Your session has been voided.</div>
          <div className={classes.confirmationText}>
            Unfortunately, the session and results (if any) will have to be
            voided and we will be unable to upload your results into the MOH
            system.
          </div>
          <br />
          <div className={classes.subtitle}>Not to worry!</div>
          <div className={classes.bodyText}>
            Click on the button below and wait for the next session to begin.
          </div>
          <div className={classes.confirmationText}>
            Note: You may be assigned a different supervisor. Ensure that you
            have a new unopened ART Kit on hand before entering into the waiting
            room.
          </div>
          <div className={classes.buttonGroup}>
            <Button
              className={classes.button}
              data-cy-disconnect
              onClick={goToWaitingRoom}
            >
              Enter Waiting Room
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
