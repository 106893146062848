import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import paths from "../../utils/paths";
import * as api from "../../utils/service";

export default function Client() {
  const { bookingUuid } = useParams();
  const history = useHistory();

  useEffect(() => {
    async function fetchClientByUuid() {
      const response = await api.getClientByUuid({ uuid: bookingUuid });
      history.push(paths(response?.client));
    }
    fetchClientByUuid().catch(console.log);
  }, [bookingUuid, history]);

  return <div></div>;
}
