import * as api from "./api";
import { getAuthenticationToken } from "./authentication";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
const LOGIN_PATH = "/login";
const GET_NEXT_CLIENT_PATH = "/nextClient";
const GET_NEXT_ME_PATH = "/session";
const UPDATE_CLIENT_PATH = "/client/{clientId}";
const GET_CLIENT_BY_UUID_PATH = "/client/{uuid}";
const UPDATE_CLIENT_STATUS_PATH = "/client/{uuid}/status";
const GET_CLIENT_COUNT_PATH = "/waitingCount";
const COMPLETE_SESSION_PATH = "/completeSession/{uuid}";
const FAIL_SESSION_PATH = "/failSession/{uuid}";
const UPDATE_TEST_KIT_PATH = "/client/{uuid}/testkit";
const RESET_TIMER_PATH = "/resetTimer/{uuid}";
const RESET_USER_TO_WAITING = "/client/{uuid}/resetToWaiting";
const RESEND_CONFIRMATION_PATH = "/client/{uuid}/resetBooking";
const CANCEL_SESSION_PATH = "/client/{uuid}/cancel";
const ADD_INVOICE_PATH = "/client/{uuid}/addinvoice";
const GET_CLIENT_LIST_PATH =
  "/json/clientList?status[]=Booked&status[]=PRPP&status[]=ARTKit&status[]=VideoCheck&status[]=Waiting&status[]=InSession&status[]=Disconnected&status[]=Completed&status[]=Invalid&status[]=Cancelled&from={fromDate}&to={toDate}";

export const login = async params => {
  let rp = await api.post(API_BASE_URL + LOGIN_PATH, { ...params });
  return rp;
};

export const getNextClient = async () => {
  let rp = await api.get(API_BASE_URL + GET_NEXT_CLIENT_PATH);
  return rp;
};

export const getWaitingCount = async () => {
  let rp = await api.get(API_BASE_URL + GET_CLIENT_COUNT_PATH);
  return rp;
};

export const updateClient = async params => {
  let clientId = params.clientId;
  delete params.clientId;
  let rp = await api.post(
    API_BASE_URL + UPDATE_CLIENT_PATH.replace("{clientId}", clientId),
    { ...params }
  );
  return rp;
};

export const completeSession = async params => {
  let uuid = params.uuid;
  delete params.uuid;
  let rp = await api.post(
    API_BASE_URL + COMPLETE_SESSION_PATH.replace("{uuid}", uuid),
    { ...params }
  );
  return rp;
};

export const cancelSession = async params => {
  let uuid = params.uuid;
  delete params.uuid;
  let rp = await api.post(
    API_BASE_URL + CANCEL_SESSION_PATH.replace("{uuid}", uuid),
    { ...params }
  );
  return rp;
};

export const addInvoice = async params => {
  let uuid = params.uuid;
  delete params.uuid;
  let rp = await api.post(
    API_BASE_URL + ADD_INVOICE_PATH.replace("{uuid}", uuid),
    { ...params }
  );
  return rp;
};

export const failSession = async params => {
  let uuid = params.uuid;
  delete params.uuid;
  let rp = await api.post(
    API_BASE_URL + FAIL_SESSION_PATH.replace("{uuid}", uuid),
    { ...params }
  );
  return rp;
};

export const resendConfirmation = async params => {
  let uuid = params.uuid;
  delete params.uuid;
  let rp = await api.post(
    API_BASE_URL + RESEND_CONFIRMATION_PATH.replace("{uuid}", uuid),
    { ...params }
  );
  return rp;
};

export const getClientByUuid = async params => {
  let uuid = params.uuid;
  delete params.uuid;
  let rp = await api.get(
    API_BASE_URL + GET_CLIENT_BY_UUID_PATH.replace("{uuid}", uuid)
  );
  return rp;
};

export const updateClientStatus = async params => {
  let uuid = params.uuid;
  delete params.uuid;
  let rp = await api.post(
    API_BASE_URL + UPDATE_CLIENT_STATUS_PATH.replace("{uuid}", uuid),
    { ...params }
  );
  return rp;
};

export const getClientList = async params => {
  let fromDate = params.from;
  let toDate = params.to;
  let finalurl =
    API_BASE_URL + GET_CLIENT_LIST_PATH.replace("{fromDate}", fromDate);
  finalurl = finalurl.replace("{toDate}", toDate);
  let rp = await api.get(finalurl);
  return rp;
};

export const resetTimer = async params => {
  let uuid = params.uuid;
  delete params.uuid;
  let rp = await api.post(
    API_BASE_URL + RESET_TIMER_PATH.replace("{uuid}", uuid),
    { ...params }
  );
  return rp;
};

export const me = async () => {
  const accessToken = await getAuthenticationToken();
  if (!accessToken) return null;
  let rp = await api.get(API_BASE_URL + GET_NEXT_ME_PATH);
  if (rp?.supervisor) {
    rp = { ok: rp.ok, supervisor: { ...rp } };
    delete rp.supervisor.ok;
  }
  return rp;
};

export const setCookie = (name, value = "", days = 30) => {
  api.setCookie(name, value, days);
};

export const getCookie = name => {
  return api.getCookie(name);
};

export const eraseCookie = name => {
  api.eraseCookie(name);
};

export const updateTestKit = async params => {
  let uuid = params.uuid;
  delete params.uuid;
  let rp = await api.post(
    API_BASE_URL + UPDATE_TEST_KIT_PATH.replace("{uuid}", uuid),
    { ...params }
  );
  return rp;
};

export const resetClientToWaiting = async params => {
  let uuid = params.uuid;

  let rp = await api.post(
    API_BASE_URL + RESET_USER_TO_WAITING.replace("{uuid}", uuid)
  );
  return rp;
};
