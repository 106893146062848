module.exports = (client = {}) => {
  const { status, bookingUuid, twilio } = client;
  if (status === "Booked") return `/booking-precall/${bookingUuid}`;
  if (status === "PRPP") return `/registration/${bookingUuid}`;
  if (status === "ARTKit") return `/update-test-kit/${bookingUuid}`;
  if (status === "VideoCheck") return `/check-devices/${bookingUuid}`;
  if (status === "Waiting") return `/waiting-room/${bookingUuid}`;
  if (status === "Disconnected") return `/disconnected/${bookingUuid}`;
  if (status === "Failed") return `/failed/${bookingUuid}`;
  if (status === "Missed") return `/missed-page/${bookingUuid}`;
  if (status === "Completed") return `/completed/${bookingUuid}`;
  if (status === "Cancelled") return `/cancelled/${bookingUuid}`;
  if (status === "InSession" && client.twilio?.accessTokens)
    return `/connect/client/${bookingUuid}/${twilio.accessTokens.client}`;
  if (status === "InSession" && !client.twilio?.accessTokens)
    return `/waiting-room/${bookingUuid}`;
  return "/client-home";
};
