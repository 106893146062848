import React, { useEffect, useState, useCallback } from "react";
import BgKitIcon from "../../../icons/BgKitIcon";
import LogoBlackIcon from "../../../icons/LogoBlackIcon";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { useAppState } from "../../../state";
import VideoInputList from "../../DeviceSelectionDialog/VideoInputList/VideoInputList";
import AudioInputList from "../../DeviceSelectionDialog/AudioInputList/AudioInputList";
import AudioOutputList from "../../DeviceSelectionDialog/AudioOutputList/AudioOutputList";
import { VideoProvider } from "../../VideoProvider";
import useConnectionOptions from "../../../utils/useConnectionOptions/useConnectionOptions";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import VideoTrack from "../../VideoTrack/VideoTrack";
import { LocalAudioTrack, LocalVideoTrack } from "twilio-video";
import { useHistory, useParams } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import * as api from "../../../utils/service";
import Help from "../../Help/Help";
import paths from "../../../utils/paths";
import Spinner from "../../Spinner/Spinner";

const useStyles = makeStyles((theme: Theme) => ({
  fullWidth: {
    minWidth: "100%"
  },
  flexEnd: {
    justifyContent: "flex-end",
    display: "flex"
  },
  passcodeContainer1: {
    marginTop: "15px",
    position: "relative"
  },
  textBold: {
    fontWeight: "bold"
  },
  label: {
    fontSize: "14px"
  },
  textDirection: {
    borderBottom: "1px solid #282828",
    color: "#282828",
    marginTop: "10px",
    display: "block",
    width: "fit-content",
    fontWeight: 400,
    fontFamily: "Avenir Next LT Pro",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px"
    }
  },
  boxKit: {
    width: "485px",
    borderRadius: "4px",
    boxShadow: "0px 2px 4px 0px rgba(40, 42, 43, 0.3)",
    position: "relative",
    margin: "0 auto",
    padding: "40px",
    background: "#fff",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      height: "auto",
      width: "calc(100% - 40px)",
      maxWidth: "400px"
    },
    "& svg": {
      position: "absolute",
      right: "15px",
      top: "15px"
    }
  },
  device: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Avenir Next LT Pro",
    overflow: "hidden"
  },
  topKit: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "50px",
    position: "relative"
  },
  contentKit: {
    position: "absolute",
    "& input": {
      position: "relative"
    }
  },
  titleText: {
    fontSize: "42px",
    fontWeight: 500,
    margin: "30px 0",
    fontFamily: "Avenir Next LT Pro",
    [theme.breakpoints.down("xs")]: {
      fontSize: "36px"
    }
  },
  parText: {
    fontSize: "24px",
    marginBottom: "15px",
    fontWeight: 600,
    fontFamily: "Avenir Next LT Pro"
  },
  tooltips: {
    fontSize: "12px",
    padding: "10px"
  },
  content: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    }
  },
  boxMedia: {
    width: "414px",
    minWidth: "414px",
    height: "340px",
    background: "#000000",
    border: "none",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      minWidth: "unset",
      width: "100%"
    },
    "& h6": {
      display: "none"
    },
    "& p": {
      display: "none"
    },
    "& video": {
      width: "100%"
    },
    preview: {
      width: "100% !important"
    }
  },
  boxDevice: {
    marginLeft: "30px",
    width: "414px",
    minWidth: "414px",
    "& video": {
      display: "none"
    },
    "& preview": {
      display: "none"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: "0",
      marginTop: "30px",
      minWidth: "unset"
    }
  },
  preview: {
    width: "100%",
    maxHeight: "100%",
    height: "100%",
    "& video": {
      maxHeight: "100%"
    }
  },
  containerDevice: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: "931px",
    padding: "30px",
    alignItems: "center",
    boxShadow: "0px 2px 4px 0px rgb(40 42 43 / 30%)",
    borderRadius: "6px",
    background: "#fff",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90%",
      marginBottom: "20px"
    }
  },
  submitButton: {
    color: "#fff !important",
    padding: "13px 30px",
    fontSize: "24px",
    marginTop: "53px",
    fontWeight: 600,
    fontFamily: "Avenir Next LT Pro",
    letterSpacing: "1.5px",
    width: "406px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "0"
    },
    background: "#000 !important"
  },
  bottomDevice: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  posiDevice: {
    position: "absolute",
    top: "0",
    left: "50%",
    transform: "translateX(-50%)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    }
  },
  titleText2: {
    fontSize: "24px",
    padding: "0",
    marginBottom: "30px",
    fontWeight: 600,
    fontFamily: "Avenir Next LT Pro",
    lineHeight: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px"
    }
  },
  textBorderBottom: {
    borderBottom: "1px solid #282828",
    color: "#282828",
    fontSize: "16px",
    fontWeight: 600
  },
  textSpeaker: {
    color: "#144FE7"
  },
  speaker: {
    display: "none",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center"
    }
  },
  boxMic: {
    position: "absolute",
    bottom: "20px",
    zIndex: 20,
    background: "rgba(0, 0, 0, 0.4)",
    height: "56px",
    width: "56px",
    display: "none",
    justifyContent: " center",
    alignItems: "center",
    borderRadius: "100px",
    border: "1px solid white",
    cursor: "pointer",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.4)"
    }
  },
  disableButton: {
    color: "#fff !important",
    padding: "13px 30px",
    fontSize: "24px",
    marginTop: "53px",
    fontWeight: 600,
    fontFamily: "Avenir Next LT Pro",
    letterSpacing: "1.5px",
    width: "406px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "0"
    },
    backgroundColor: "#D9D9D9",
    "&:hover": {
      background: "#D9D9D9"
    }
  },
  tooltip: {
    width: "276.75px",
    color: "#3E3D3D",
    background: "#FFFFFF !important",
    border: "1px solid #CFCFCF",
    boxSizing: "border-box",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
    fontFamily: "Avenir Next LT Pro",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "24px",
    textAlign: "center",
    padding: "12.5px",
    borderRadius: "10px",
    top: "10px !important",
    margin: "25px 0 !important"
  },
  help: {
    position: "absolute",
    top: "5px",
    right: "0px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      right: "-240px"
    },
    [theme.breakpoints.down("xs")]: {
      right: "-45px"
    }
  }
}));

export function GetDevices() {
  const classes = useStyles();
  const { localTracks } = useVideoContext();
  const { getAudioAndVideoTracks } = useVideoContext();
  const localVideoTrack = localTracks.find(
    track => track.kind === "video"
  ) as LocalVideoTrack;
  const localAudioTrack = localTracks.find(
    track => track.kind === "audio"
  ) as LocalAudioTrack;

  const history = useHistory();
  const { bookingUuid } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const goToWaitingRoom = async () => {
    try {
      const { result: updatedClient } = await api.updateClientStatus({
        uuid: bookingUuid,
        status: "Waiting"
      });
      history.push(paths(updatedClient));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchClient = useCallback(async () => {
    const { client } = await api.getClientByUuid({ uuid: bookingUuid });
    if (client?.status === "VideoCheck") setIsLoading(false);
    else history.push(paths(client));
  }, [history, bookingUuid]);

  useEffect(() => {
    fetchClient().catch(console.log);
  }, [fetchClient]);

  useEffect(() => {
    getAudioAndVideoTracks().catch(error => {
      console.log("Error acquiring local media:");
      console.dir(error);
    });
  }, [getAudioAndVideoTracks]);

  if (isLoading) return <Spinner />;

  return (
    <div className={classes.device}>
      <BgKitIcon />
      <div className={classes.posiDevice}>
        <div className={classes.topKit}>
          <LogoBlackIcon />
          <div className={classes.help}>
            <Help />
          </div>
          <Typography variant="h2" className={classes.titleText}>
            Tele-ART
          </Typography>
        </div>
        <div className={classes.containerDevice}>
          <Typography variant="h4" className={classes.titleText2}>
            Camera and Microphone Check
          </Typography>

          <div className={classes.content}>
            <div className={classes.boxMedia}>
              {localVideoTrack && (
                <div className={classes.preview}>
                  <VideoTrack isLocal track={localVideoTrack} />
                </div>
              )}
            </div>
            <div className={classes.boxDevice}>
              <VideoInputList hidePreview={true} />
              <AudioInputList hidePreview={true} />
              <AudioOutputList hidePreview={true} />
              <p>
                Please ensure that your camera, microphone and speaker are
                working well before joining the call. You should be in a quiet
                room or enclosed area, with a strong internet connection. <br />{" "}
                <br />
                Should there be an obstructed line of sight or a disconnection,
                the session and results (if any) will have to be voided.
              </p>
            </div>
          </div>

          <div className={classes.bottomDevice}>
            <Tooltip
              title={
                !(localVideoTrack && localAudioTrack) ? (
                  <div>
                    Please allow your browser to access the <br /> device camera
                    and microphone to proceed.
                  </div>
                ) : (
                  ""
                )
              }
              placement={"top"}
              classes={
                !(localVideoTrack && localAudioTrack)
                  ? { tooltip: classes.tooltip }
                  : undefined
              }
              PopperProps={{
                disablePortal: true
              }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={
                  localVideoTrack && localAudioTrack
                    ? classes.submitButton
                    : classes.disableButton
                }
                onClick={goToWaitingRoom}
              >
                Continue
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function CheckDevices() {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <GetDevices />
    </VideoProvider>
  );
}
