import React from "react";
import AudioLevelIndicator from "../../AudioLevelIndicator/AudioLevelIndicator";
import { LocalAudioTrack } from "twilio-video";
import {
  FormControl,
  MenuItem,
  Typography,
  Select,
  Grid
} from "@material-ui/core";
import { SELECTED_AUDIO_INPUT_KEY } from "../../../constants";
import {
  makeStyles,
  Theme,
  createTheme,
  ThemeProvider
} from "@material-ui/core/styles";
import useDevices from "../../../hooks/useDevices/useDevices";
import useMediaStreamTrack from "../../../hooks/useMediaStreamTrack/useMediaStreamTrack";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import Mic1Icon from "../../../icons/Mic1Icon";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const theme = createTheme({
  overrides: {
    MuiListItem: {
      root: {
        "&.Mui-selected": {
          backgroundColor: "#E1F3FE"
        },
        "&:hover": {
          backgroundColor: "#F5F5F5!important"
        }
      }
    }
  }
});

const useStyles = makeStyles((theme: Theme) => ({
  boxAudio: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #C7C7C7",
    borderRadius: "4px",
    paddingLeft: "0px",
    paddingTop: "5px",
    marginBottom: "15px",
    fontFamily: "Avenir Next LT Pro",
    position: "relative",
    "& svg": {
      position: "absolute",
      left: "5px",
      top: "20px"
    },
    "& div[class*='selectMenu']": {
      paddingLeft: "34px"
    }
  },
  inputSelect: {
    width: "100%",
    position: "relative",
    "& fieldset": {
      border: "none"
    },
    "& svg": {
      right: "10px !important",
      top: "10px !important",
      left: "unset",
      fill: "#282828",
      width: "30px",
      height: "30px"
    }
  },
  textTitle: {
    fontSize: "10px",
    marginLeft: "34px",
    marginBottom: "-7px",
    color: "#828282"
  },
  inputSelect1: {
    padding: "16px 16px !important",
    fontSize: "14px"
  },
  textNoLocal: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  textPadding: {
    marginLeft: "12px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingLeft: "24px"
  },
  selectOptions: {
    position: "relative",
    "& .MuiListItem-root": {
      borderRadius: 0,
      color: "red"
    },
    "& .MuiList-padding": {
      padding: "0 !important"
    },
    backgroundColor: "#fff",
    padding: "0 !important",
    margin: "0 !important",
    borderRight: "1px solid #C7C7C7",
    borderLeft: "1px solid #C7C7C7",
    borderBottom: "1px solid #C7C7C7",
    boxShadow: "none"
  }
}));

export default function AudioInputList(props: { hidePreview: any }) {
  const hidePreview = props.hidePreview;
  const { audioInputDevices } = useDevices();
  const { localTracks } = useVideoContext();
  const classes = useStyles();

  const MenuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        maxHeight: "auto",
        width: 240
      }
    }
  };
  MenuProps.PaperProps.className = classes.selectOptions;
  const localAudioTrack = localTracks.find(
    track => track.kind === "audio"
  ) as LocalAudioTrack;
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

  function replaceTrack(newDeviceId: string) {
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
  }

  return (
    <div className={hidePreview ? classes.boxAudio : ""}>
      {hidePreview ? <Mic1Icon /> : ""}
      <Grid container alignItems="center" justifyContent="space-between">
        {audioInputDevices.length > 1 ? (
          <div className={hidePreview ? classes.inputSelect : ""}>
            {hidePreview ? (
              <Typography
                className={classes.textTitle}
                variant="subtitle2"
                gutterBottom
              >
                Microphone
              </Typography>
            ) : (
              <Typography variant="subtitle2" gutterBottom>
                Audio Intput
              </Typography>
            )}
            <ThemeProvider theme={theme}>
              <FormControl fullWidth>
                <Select
                  onChange={e => replaceTrack(e.target.value as string)}
                  value={localAudioInputDeviceId || ""}
                  variant="outlined"
                  MenuProps={MenuProps}
                  IconComponent={
                    hidePreview ? KeyboardArrowDownIcon : undefined
                  }
                >
                  {audioInputDevices.map(device => (
                    <MenuItem
                      className={classes.inputSelect1}
                      value={device.deviceId}
                      key={device.deviceId}
                    >
                      {device.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ThemeProvider>
          </div>
        ) : (
          <div>
            {hidePreview ? (
              <Typography
                className={classes.textTitle}
                variant="subtitle2"
                gutterBottom
              >
                Microphone
              </Typography>
            ) : (
              <Typography variant="subtitle2" gutterBottom>
                Audio Intput
              </Typography>
            )}
            <Typography
              className={
                hidePreview ? classes.textPadding : classes.textNoLocal
              }
            >
              {localAudioTrack?.mediaStreamTrack.label || "No Local Audio"}
            </Typography>
          </div>
        )}
        {hidePreview ? (
          ""
        ) : (
          <AudioLevelIndicator audioTrack={localAudioTrack} color="black" />
        )}
      </Grid>
    </div>
  );
}
