import React, { useState, useEffect, useCallback } from "react";
import DeviceSelectionScreen from "./DeviceSelectionScreen/DeviceSelectionScreen";
import IntroContainer from "../IntroContainer/IntroContainer";
import MediaErrorSnackbar from "./MediaErrorSnackbar/MediaErrorSnackbar";
import { useParams, useHistory } from "react-router-dom";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import * as api from "../../utils/service";
import paths from "../../utils/paths";
import Spinner from "../Spinner/Spinner";

export enum Steps {
  roomNameStep,
  deviceSelectionStep
}

export default function PreJoinScreens() {
  const { getAudioAndVideoTracks } = useVideoContext();
  const { bookingUuid } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [mediaError, setMediaError] = useState<Error>();
  const [mediaAcquired, setMediaAcquired] = useState(false);

  const fetchClient = useCallback(async () => {
    const { client } = await api.getClientByUuid({ uuid: bookingUuid });
    if (client?.status === "InSession") setIsLoading(false);
    else history.push(paths(client));
  }, [history, bookingUuid]);

  useEffect(() => {
    fetchClient().catch(console.log);
  }, [fetchClient]);

  useEffect(() => {
    if (!mediaError && !mediaAcquired) {
      getAudioAndVideoTracks()
        .then(() => {
          setMediaAcquired(true);
        })
        .catch(error => {
          console.log("Error acquiring local media:");
          console.dir(error);
          setMediaError(error);
        });
    }
  }, [getAudioAndVideoTracks, mediaError, mediaAcquired]);

  if (isLoading) return <Spinner />;

  return (
    <IntroContainer normal>
      <MediaErrorSnackbar error={mediaError} />
      {mediaAcquired && <DeviceSelectionScreen />}
    </IntroContainer>
  );
}
