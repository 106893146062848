import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as api from "../../utils/service";
import { uid } from "../../utils/index";

export async function whoami() {
  try {
    let rp = await api.me();
    return rp;
  } catch (err) {
    return null;
  }
}

export function getErrorMessage(message: string) {
  switch (message) {
    case "passcode incorrect":
      return "Passcode is incorrect";
    case "passcode expired":
      return "Passcode has expired";
    default:
      return message;
  }
}

export default function usePWDAuth() {
  const history = useHistory();

  const [user, setUser] = useState<{
    displayName: undefined;
    photoURL: undefined;
    passcode: string;
  } | null>(null);
  const [isAuthReady, setIsAuthReady] = useState(false);
  const [isTrackEnabled, setIsTrackEnabled] = useState(false);

  const updateRecordingRules = useCallback(
    async (room_sid, rules) => {
      return fetch("/recordingrules", {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ room_sid, rules, passcode: user?.passcode }),
        method: "POST"
      }).then(async res => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const error = new Error(
            jsonResponse.error?.message ||
              "There was an error updating recording rules"
          );
          error.code = jsonResponse.error?.code;

          return Promise.reject(error);
        }

        return jsonResponse;
      });
    },
    [user]
  );

  useEffect(() => {
    whoami()
      .then(us => {
        if (us) {
          setUser({ ...us } as any);
        }
      })
      .then(() => setIsAuthReady(true));
  }, [history]);

  const signIn = useCallback((us: any) => {
    setUser({ ...us } as any);
    window.sessionStorage.setItem(
      "passcode",
      us?.supervisor?.supervisor?._id || us?.supervisor?.supervisor?.id
    );
    return Promise.resolve();
    // eslint-disable-next-line
  }, []);

  const signOut = useCallback(() => {
    setUser(null);
    window.sessionStorage.removeItem("passcode");
    api.eraseCookie("xapiKey");
    return Promise.resolve();
  }, []);

  return {
    user,
    isAuthReady,
    signIn,
    signOut,
    updateRecordingRules,
    isTrackEnabled,
    setIsTrackEnabled
  };
}
