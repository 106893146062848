import React from "react";

export default function LogoIcon() {
  return (
    <svg
      width="200"
      height="27"
      viewBox="0 0 200 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.8689 20.0698C41.8152 20.043 41.8152 20.0162 41.8152 19.9893L41.4932 16.6891C41.4932 16.6086 41.5469 16.5281 41.6006 16.5281C41.6209 16.5309 41.6398 16.5404 41.6542 16.5549C42.4323 17.0111 45.4643 18.5136 50.4817 18.5136C53.7283 18.5136 55.7407 17.7087 55.7407 16.5281C55.7407 15.6963 53.9698 14.9182 49.5963 14.9182C41.3859 14.9182 41.0371 11.4838 41.0371 10.2496C41.0371 6.17123 46.0009 5.17847 50.3207 5.17847C54.1844 5.17847 56.8944 6.33221 57.3505 6.54686C57.4042 6.54686 57.4042 6.60053 57.4042 6.62736L57.6188 9.82028C57.6457 9.87394 57.5652 9.95444 57.5115 9.95444C57.4912 9.9516 57.4723 9.94218 57.4579 9.92761C56.4383 9.31049 53.5941 8.12991 50.3207 8.12991C44.9276 8.12991 44.0154 9.39098 44.0154 10.2496C44.0154 11.457 46.1619 11.9668 49.6231 11.9668C52.7355 11.9668 58.8799 12.3156 58.8799 16.6354C58.8799 17.6282 58.1823 21.4651 50.4817 21.4651C45.4374 21.4919 42.3518 20.2845 41.8689 20.0698Z"
        fill="white"
      />
      <path
        d="M135.966 21.1431C135.916 21.1442 135.867 21.1352 135.821 21.1167C135.774 21.0982 135.733 21.0706 135.698 21.0355C135.662 21.0004 135.635 20.9585 135.616 20.9125C135.598 20.8664 135.589 20.8171 135.59 20.7674V5.87607C135.589 5.8278 135.599 5.77994 135.618 5.73558C135.637 5.69122 135.665 5.65134 135.701 5.61849C135.736 5.58564 135.778 5.56055 135.823 5.54484C135.869 5.52913 135.917 5.52314 135.966 5.52726H143.854C149.891 5.52726 153.46 8.61286 153.46 13.3352C153.46 17.172 151.098 21.1431 143.854 21.1431H135.966ZM138.541 17.8428C138.542 17.9376 138.579 18.0286 138.643 18.0981C138.708 18.1675 138.796 18.2105 138.89 18.2185H143.854C148.657 18.2185 150.508 16.8501 150.508 13.362C150.508 9.14949 146.322 8.50554 143.854 8.50554H138.917C138.869 8.50727 138.823 8.51836 138.779 8.53818C138.736 8.558 138.697 8.58616 138.665 8.62104C138.632 8.65593 138.607 8.69687 138.591 8.7415C138.574 8.78614 138.566 8.8336 138.568 8.88117V17.8428H138.541Z"
        fill="white"
      />
      <path
        d="M158.719 13.3351C158.719 7.53958 164.541 5.20526 168.459 5.20526C172.671 5.20526 178.225 7.8079 178.225 13.3351C178.225 18.1916 174.039 21.4919 168.459 21.4919C164.461 21.4919 158.719 18.5404 158.719 13.3351ZM161.67 13.3351C161.67 16.2061 164.702 18.5404 168.459 18.5404C172.215 18.5404 175.247 16.2061 175.247 13.3351C175.247 10.4642 172.188 8.1567 168.432 8.1567C164.675 8.1567 161.67 10.491 161.67 13.3351Z"
        fill="white"
      />
      <path
        d="M77.6079 5.52728H65.6143C65.5686 5.53203 65.5264 5.55416 65.4964 5.5891C65.4665 5.62404 65.451 5.66914 65.4534 5.7151V8.23724V8.2909C65.4581 8.33668 65.4802 8.37889 65.5152 8.40884C65.5501 8.43879 65.5952 8.4542 65.6412 8.45189H77.5811C78.6007 8.45189 79.5129 9.49831 79.5129 10.6252C79.5101 11.1438 79.3028 11.6404 78.9361 12.0071C78.5694 12.3738 78.0729 12.5811 77.5542 12.5839H65.6143C65.5686 12.5887 65.5264 12.6108 65.4964 12.6457C65.4665 12.6807 65.451 12.7258 65.4534 12.7717V20.9553C65.4565 20.9969 65.4744 21.0361 65.504 21.0657C65.5335 21.0952 65.5727 21.1132 65.6143 21.1163H68.217C68.2586 21.1132 68.2978 21.0952 68.3274 21.0657C68.3569 21.0361 68.3749 20.9969 68.378 20.9553V15.6963C68.3748 15.6745 68.3768 15.6522 68.3838 15.6312C68.3908 15.6102 68.4025 15.5912 68.4182 15.5756C68.4338 15.5599 68.4528 15.5482 68.4738 15.5412C68.4948 15.5342 68.5171 15.5322 68.5389 15.5354H77.5006C78.797 15.5455 80.0452 15.0441 80.9744 14.14C81.9036 13.2359 82.439 12.002 82.4644 10.7057C82.518 7.91527 80.2642 5.52728 77.6079 5.52728Z"
        fill="white"
      />
      <path
        d="M105.297 18.1916H92.981C92.9145 18.1849 92.853 18.1537 92.8083 18.1041C92.7637 18.0545 92.7391 17.99 92.7395 17.9233V14.9719C92.7387 14.9505 92.7424 14.9292 92.7502 14.9094C92.758 14.8895 92.7699 14.8715 92.785 14.8564C92.8001 14.8413 92.8181 14.8294 92.838 14.8216C92.8579 14.8138 92.8791 14.8101 92.9005 14.8109H101.272C101.311 14.8139 101.349 14.8015 101.38 14.7765C101.41 14.7514 101.429 14.7156 101.433 14.6767L102.265 12.0204C102.269 11.9971 102.267 11.9733 102.261 11.9505C102.255 11.9278 102.243 11.9067 102.228 11.8888C102.213 11.8709 102.194 11.8566 102.172 11.8468C102.151 11.8371 102.127 11.8323 102.104 11.8326H92.8736C92.832 11.8295 92.7928 11.8115 92.7632 11.782C92.7337 11.7524 92.7157 11.7133 92.7126 11.6716V8.72017C92.7126 8.65612 92.7381 8.5947 92.7834 8.54941C92.8287 8.50413 92.8901 8.47868 92.9541 8.47868H104.438C104.471 8.47425 104.503 8.46227 104.531 8.44367C104.559 8.42507 104.582 8.40034 104.599 8.37136L105.431 5.71506C105.484 5.63457 105.377 5.52724 105.27 5.52724H90.0027C89.969 5.5243 89.935 5.52831 89.9029 5.53905C89.8708 5.54979 89.8412 5.56703 89.8161 5.58967C89.7909 5.61232 89.7706 5.6399 89.7566 5.67071C89.7425 5.70151 89.735 5.73488 89.7344 5.76873V20.9284C89.735 20.9623 89.7425 20.9956 89.7566 21.0264C89.7706 21.0572 89.7909 21.0848 89.8161 21.1075C89.8412 21.1301 89.8708 21.1473 89.9029 21.1581C89.935 21.1688 89.969 21.1728 90.0027 21.1699H104.438C104.471 21.1654 104.503 21.1535 104.531 21.1349C104.559 21.1163 104.582 21.0915 104.599 21.0626L105.431 18.4063C105.442 18.3836 105.448 18.3582 105.447 18.3328C105.446 18.3073 105.438 18.2826 105.424 18.2609C105.411 18.2393 105.392 18.2216 105.37 18.2094C105.347 18.1973 105.322 18.1911 105.297 18.1916Z"
        fill="white"
      />
      <path
        d="M128.373 18.1916H116.057C115.991 18.1849 115.929 18.1537 115.885 18.1041C115.84 18.0545 115.815 17.99 115.816 17.9233V14.9719C115.815 14.9505 115.819 14.9292 115.826 14.9094C115.834 14.8895 115.846 14.8715 115.861 14.8564C115.876 14.8413 115.894 14.8294 115.914 14.8216C115.934 14.8138 115.955 14.8101 115.977 14.8109H124.348C124.387 14.8139 124.426 14.8015 124.456 14.7765C124.486 14.7514 124.505 14.7156 124.509 14.6767L125.341 12.0204C125.345 11.9971 125.343 11.9733 125.337 11.9505C125.331 11.9278 125.32 11.9067 125.304 11.8888C125.289 11.8709 125.27 11.8566 125.248 11.8468C125.227 11.8371 125.203 11.8323 125.18 11.8326H115.95C115.908 11.8295 115.869 11.8115 115.839 11.782C115.81 11.7524 115.792 11.7133 115.789 11.6716V8.72017C115.789 8.65612 115.814 8.5947 115.86 8.54941C115.905 8.50413 115.966 8.47868 116.03 8.47868H127.514C127.547 8.47425 127.579 8.46227 127.607 8.44367C127.635 8.42507 127.658 8.40034 127.675 8.37136L128.507 5.71506C128.561 5.63457 128.453 5.52724 128.346 5.52724H113.079C113.045 5.5243 113.011 5.52831 112.979 5.53905C112.947 5.54979 112.917 5.56703 112.892 5.58967C112.867 5.61232 112.847 5.6399 112.833 5.67071C112.819 5.70151 112.811 5.73488 112.811 5.76873V20.9284C112.811 20.9623 112.819 20.9956 112.833 21.0264C112.847 21.0572 112.867 21.0848 112.892 21.1075C112.917 21.1301 112.947 21.1473 112.979 21.1581C113.011 21.1688 113.045 21.1728 113.079 21.1699H127.514C127.547 21.1654 127.579 21.1535 127.607 21.1349C127.635 21.1163 127.658 21.0915 127.675 21.0626L128.507 18.4063C128.518 18.3836 128.524 18.3582 128.523 18.3328C128.522 18.3073 128.514 18.2826 128.501 18.2609C128.487 18.2393 128.468 18.2216 128.446 18.2094C128.423 18.1973 128.398 18.1911 128.373 18.1916Z"
        fill="white"
      />
      <path
        d="M199.825 18.2721H193.197C188.341 18.2721 186.463 16.8769 186.463 13.362C186.463 9.09583 190.702 8.45188 193.197 8.45188H198.993C199.028 8.45301 199.062 8.44327 199.091 8.42403C199.119 8.40479 199.142 8.377 199.154 8.34456L199.986 5.6346C200.039 5.5541 199.932 5.44678 199.825 5.44678H193.197C187.08 5.44678 183.484 8.55921 183.484 13.362C183.484 17.2525 185.872 21.2772 193.197 21.2772H199.878C199.959 21.2772 199.986 21.2504 199.986 21.1699V18.4331C199.989 18.4112 199.987 18.3889 199.98 18.368C199.973 18.347 199.961 18.328 199.945 18.3123C199.93 18.2967 199.911 18.2849 199.89 18.278C199.869 18.271 199.847 18.269 199.825 18.2721Z"
        fill="white"
      />
      <path
        d="M33.041 9.49827H23.6232L27.0845 0.697613C27.2186 0.295144 27.0576 0.0268309 26.682 0.0268309L18.8204 0C18.6202 0.0240526 18.4292 0.098099 18.2651 0.21532C18.101 0.332542 17.969 0.48917 17.8813 0.670782L14.4201 9.41778L14.3933 9.47144H3.15096C3.09643 9.47491 3.04484 9.49739 3.00518 9.53497C2.96552 9.57256 2.9403 9.62286 2.93392 9.67713C2.92754 9.73139 2.94039 9.78618 2.97024 9.83194C3.0001 9.87771 3.04506 9.91155 3.0973 9.92758L13.0517 11.7521C13.1062 11.7631 13.1548 11.7935 13.1888 11.8375C13.2227 11.8815 13.2395 11.9363 13.2363 11.9918C13.233 12.0473 13.2098 12.0997 13.171 12.1395C13.1322 12.1793 13.0803 12.2037 13.0249 12.2082L1.91672 12.879C1.86219 12.8825 1.8106 12.905 1.77094 12.9425C1.73128 12.9801 1.70607 13.0304 1.69968 13.0847C1.6933 13.139 1.70615 13.1938 1.73601 13.2395C1.76586 13.2853 1.81082 13.3191 1.86306 13.3351L11.576 14.6499L11.9248 14.7035C11.9802 14.7081 12.0321 14.7325 12.0709 14.7723C12.1097 14.812 12.1329 14.8645 12.1362 14.92C12.1395 14.9755 12.1226 15.0302 12.0887 15.0743C12.0548 15.1183 12.0061 15.1486 11.9516 15.1597L11.576 15.2402L0.172688 17.1184C0.121583 17.1291 0.0760479 17.1579 0.0444149 17.1994C0.0127819 17.241 -0.00283384 17.2925 0.000423494 17.3446C0.00368083 17.3967 0.0255927 17.4459 0.0621527 17.4832C0.0987128 17.5205 0.147476 17.5434 0.199519 17.5477H11.3345L8.00742 26.0263C7.87327 26.4288 8.03426 26.6971 8.40989 26.6971H16.2178C16.418 26.6731 16.609 26.599 16.7731 26.4818C16.9372 26.3646 17.0692 26.208 17.1569 26.0263L20.484 17.5477H29.9018C30.1075 17.5439 30.3084 17.4846 30.4832 17.376C30.658 17.2673 30.8001 17.1134 30.8945 16.9305L33.6045 10.0617C33.685 9.73976 33.4703 9.49827 33.041 9.49827Z"
        fill="url(#paint0_linear_16_914)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_16_914"
          x1="8.155"
          y1="2.39067"
          x2="24.3826"
          y2="21.7307"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3" stopColor="#00C3B5" />
          <stop offset="1" stopColor="#0097D5" />
        </linearGradient>
      </defs>
    </svg>
  );
}
