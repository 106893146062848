import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  Participant,
  RemoteAudioTrack,
  RemoteVideoTrack
} from "twilio-video";

//import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import AvatarIcon from "../../icons/AvatarIcon";
//import NetworkQualityLevel from '../NetworkQualityLevel/NetworkQualityLevel';
import PinIcon from "./PinIcon/PinIcon";
import ScreenShareIcon from "../../icons/ScreenShareIcon";
import Typography from "@material-ui/core/Typography";

import useIsTrackSwitchedOff from "../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff";
import usePublications from "../../hooks/usePublications/usePublications";
import useTrack from "../../hooks/useTrack/useTrack";
import useParticipantIsReconnecting from "../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting";
import { useAppState } from "../../state";
import useRole from "../../state/useRole/useRole";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      height: 0,
      marginBottom: "0.5em",
      "& video": {
        filter: "none",
        objectFit: "contain !important"
      },
      borderRadius: "4px",
      paddingTop: `calc(${(9 / 16) * 100}% - ${
        theme.participantBorderWidth
      }px)`,
      background: "black",
      [theme.breakpoints.down("sm")]: {
        // height: theme.sidebarMobileHeight,
        height: "100%",
        // width: `${(theme.sidebarMobileHeight * 16) / 9}px`,
        width: "100%",
        fontSize: "12px",
        paddingTop: `${theme.sidebarMobileHeight - 2}px`,
        margin: "0 20px"
      }
    },
    innerContainer: {
      position: "absolute",
      left: 0,
      height: "495px",
      maxHeight: "493px",
      width: "95%",
      top: "25px",
      margin: "0 7px 0 15px",
      [theme.breakpoints.down("sm")]: {
        height: "100%"
      }
    },
    innerContainerS: {
      position: "absolute",
      right: 0,
      height: "100%",
      maxHeight: "204px",
      width: "40%",
      top: "25px",
      margin: "0 30px 0 15px",
      [theme.breakpoints.down("sm")]: {
        height: "100%"
      }
    },
    infoContainer: {
      position: "absolute",
      zIndex: 2,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "495px",
      maxHeight: "493px",
      width: "95%",
      background: "transparent",
      top: "25px",
      margin: "0 7px 0 15px",
      border: "1px solid white",
      [theme.breakpoints.down("md")]: {
        width: "93%"
      },
      [theme.breakpoints.down("sm")]: {
        top: "0",
        height: "100%",
        margin: "0 auto",
        width: "100%"
      },
      [theme.breakpoints.down("xs")]: {
        height: "95%"
      }
    },
    infoContainerS: {
      position: "absolute",
      zIndex: 2,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      maxHeight: "204px",
      width: "40%",
      background: "transparent",
      top: "25px",
      right: "0",
      margin: "0 30px 0 15px",
      border: "1px solid white",
      [theme.breakpoints.down("md")]: {
        width: "93%"
      },
      [theme.breakpoints.down("sm")]: {
        top: "0",
        height: "100%",
        margin: "0 auto",
        width: "100%"
      },
      [theme.breakpoints.down("xs")]: {
        height: "95%"
      }
    },
    avatarContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#282828",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1,
      [theme.breakpoints.down("sm")]: {
        "& svg": {
          transform: "scale(0.7)"
        }
      }
    },
    reconnectingContainer: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "rgba(40, 42, 43, 0.75)",
      zIndex: 1
    },
    screenShareIconContainer: {
      background: "rgba(0, 0, 0, 0.5)",
      padding: "0.18em 0.3em",
      marginRight: "0.3em",
      display: "flex",
      "& path": {
        fill: "white"
      }
    },
    identity: {
      background: "rgba(0, 0, 0, 0.5)",
      color: "white",
      padding: "10px",
      margin: 0,
      display: "flex",
      alignItems: "center"
    },
    infoRowBottom: {
      display: "flex",
      justifyContent: "space-between",
      position: "absolute",
      bottom: 0,
      left: 0
    },
    typeography: {
      color: "white",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.75rem"
      }
    },
    hideParticipant: {
      display: "none"
    },
    cursorPointer: {
      cursor: "pointer"
    }
  })
);

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick?: () => void;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
}

export default function ParticipantInfo({
  participant,
  onClick,
  isSelected,
  children,
  isLocalParticipant,
  hideParticipant
}: ParticipantInfoProps) {
  const publications = usePublications(participant);
  const role = useRole();

  const audioPublication = publications.find(p => p.kind === "audio");
  const videoPublication = publications.find(
    p => !p.trackName.includes("screen") && p.kind === "video"
  );

  const isVideoEnabled = Boolean(videoPublication);
  const isScreenShareEnabled = publications.find(p =>
    p.trackName.includes("screen")
  );

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack
  );

  // eslint-disable-next-line
  const audioTrack = useTrack(audioPublication) as
    | LocalAudioTrack
    | RemoteAudioTrack
    | undefined;
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const classes = useStyles();

  return (
    <div
      className={clsx(classes.container, {
        [classes.hideParticipant]: hideParticipant,
        [classes.cursorPointer]: Boolean(onClick)
      })}
      onClick={onClick}
      data-cy-participant={participant.identity}
      style={{ background: "unset" }}
    >
      {role === "client" ? (
        <div className={classes.infoContainer}>
          {/*<NetworkQualityLevel participant={participant} />*/}
          <div className={classes.infoRowBottom}>
            {isScreenShareEnabled && (
              <span className={classes.screenShareIconContainer}>
                <ScreenShareIcon />
              </span>
            )}
            <span className={classes.identity}>
              {/*<AudioLevelIndicator audioTrack={audioTrack} />*/}
              <Typography
                variant="body1"
                className={classes.typeography}
                component="span"
              >
                {participant.identity}
                {isLocalParticipant && " (You)"}
              </Typography>
            </span>
          </div>
          <div>{isSelected && <PinIcon />}</div>
        </div>
      ) : (
        <div className={classes.infoContainerS}>
          {/*<NetworkQualityLevel participant={participant} />*/}
          <div className={classes.infoRowBottom}>
            {isScreenShareEnabled && (
              <span className={classes.screenShareIconContainer}>
                <ScreenShareIcon />
              </span>
            )}
            <span className={classes.identity}>
              {/*<AudioLevelIndicator audioTrack={audioTrack} />*/}
              <Typography
                variant="body1"
                className={classes.typeography}
                component="span"
              >
                {participant.identity}
                {isLocalParticipant && " (You)"}
              </Typography>
            </span>
          </div>
          <div>{isSelected && <PinIcon />}</div>
        </div>
      )}

      {role === "client" ? (
        <div className={classes.innerContainer}>
          {(!isVideoEnabled || isVideoSwitchedOff) && (
            <div className={classes.avatarContainer}>
              <AvatarIcon />
            </div>
          )}
          {isParticipantReconnecting && (
            <div className={classes.reconnectingContainer}>
              <Typography variant="body1" className={`${classes.typeography}`}>
                Reconnecting...
              </Typography>
            </div>
          )}
          {children}
        </div>
      ) : (
        <div className={classes.innerContainerS}>
          {(!isVideoEnabled || isVideoSwitchedOff) && (
            <div className={classes.avatarContainer}>
              <AvatarIcon />
            </div>
          )}
          {isParticipantReconnecting && (
            <div className={classes.reconnectingContainer}>
              <Typography variant="body1" className={`${classes.typeography}`}>
                Reconnecting...
              </Typography>
            </div>
          )}
          {children}
        </div>
      )}
    </div>
  );
}
