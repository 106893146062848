import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import useChatContext from "../../hooks/useChatContext/useChatContext";
import clsx from "clsx";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chatWindowContainer: {
      background: "#282828",
      zIndex: 9,
      display: "flex",
      flexDirection: "column",
      // borderLeft: "1px solid #E4E7E9",
      maxHeight: "83%",
      marginTop: "85px",
      // padding: "20px 0 20px 20px",
      color: "white",
      fontFamily: "Avenir Next LT Pro",
      fontSize: "16px",
      position: "relative",
      marginRight: "30px",
      h6: {
        fontSize: "18px"
      },
      [theme.breakpoints.down("md")]: {
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 100,
        maxHeight: "80%",
        marginTop: "0",
        marginRight: "0px"
      },
      [theme.breakpoints.down("sm")]: {
        // maxHeight: "92%",
        // margin: "10px 20px",
        // width: "80%"
        maxHeight: "100%",
        width: "calc(100% - 120px)",
        padding: "20px 0 20px 20px"
      },
      [theme.breakpoints.down("xs")]: {
        maxHeight: "calc(100% - 56px)",
        width: "100%",
        margin: "0",
        padding: "0"
      },
      "&::after": {
        content: `''`,
        bottom: "-35px",
        right: "30px",
        height: "0",
        borderLeft: "25px solid transparent",
        borderRight: "25px solid transparent",
        borderTop: "35px solid #373737",
        position: "absolute",
        display: "inline",
        background: "transparent",
        [theme.breakpoints.down("md")]: {
          borderLeft: "15px solid transparent",
          borderRight: "15px solid transparent",
          borderTop: "25px solid #373737",
          bottom: "-25px"
        },
        [theme.breakpoints.down("sm")]: {
          borderLeft: "10px solid transparent",
          borderRight: "10px solid transparent",
          borderTop: "15px solid #373737",
          // top: "35px",
          // right: "-20px",
          transform: "rotate(-90deg)",
          bottom: "83px",
          right: "-16px"
        },
        [theme.breakpoints.down("xs")]: {
          borderLeft: "10px solid transparent",
          borderRight: "10px solid transparent",
          borderTop: "10px solid #373737",
          transform: "rotate(0deg)",
          bottom: "-9px",
          top: "unset",
          right: "30px"
        }
      }
    },
    hide: {
      display: "none"
    },
    textTile: {
      fontSize: "18px"
    },
    boxWarning: {
      padding: "10px 20px 10px 20px",
      background: "#373737",
      height: "100%",
      overflowX: "auto",
      [theme.breakpoints.down("sm")]: {
        padding: "10px 20px 10px 20px",
        background: "#373737",
        height: "100%",
        overflowX: "auto"
      },
      [theme.breakpoints.down("xs")]: {
        borderLeft: "10px solid transparent",
        borderRight: "10px solid transparent",
        borderTop: "20px solid #373737",
        transform: "rotate(0deg)",
        bottom: "-20px",
        top: "unset",
        right: "30px"
      }
    }
  })
);
export default function NotWarning(props) {
  const classes = useStyles();
  const { isRFWindowOpen } = useChatContext();
  return (
    <aside
      className={clsx(classes.chatWindowContainer, {
        [classes.hide]: !isRFWindowOpen
      })}
    >
      <div className={classes.boxWarning}>
        <Typography variant="h6" className={classes.textTile}>
          {props.content}
        </Typography>
        <ul>
          <li>
            You should be in a quiet room or enclosed area, with strong Internet
            connection.{" "}
          </li>
          <li>You should have a government-approved ART kit with you. </li>
          <li>
            As an unobstructed line of sight is needed throughout the Tele-ART
            appointment, we regret to inform you that should your call be
            disconnected, the session and results (if any) will have to be
            voided and we will be unable to upload your results into the MOH
            system. Should this happen, you can join back the same link and wait
            for the next session to begin.
          </li>
          <li>
            Please note that this call may be recorded for medical audit
            purposes.{" "}
          </li>
          <li>
            All medical information obtained will be treated in accordance with
            prevailing Singapore regulations.
          </li>
        </ul>
      </div>
    </aside>
  );
}
