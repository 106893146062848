import React from "react";

export default function BgKitIcon() {
  return (
    <svg
      width="1015"
      height="757"
      viewBox="0 0 1015 757"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M985.346 554.488L44.8182 756.106C21.9184 761.118 0 744.41 0 722.132V-110.514C0 -126.945 11.7771 -141.147 28.7883 -144.489L969.316 -346.106C992.216 -351.118 1014.13 -334.41 1014.13 -312.132V520.514C1014.13 536.666 1002.36 550.868 985.346 554.488Z"
        fill="url(#paint0_linear_2577_3562)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2577_3562"
          x1="-218.613"
          y1="465.471"
          x2="1103.32"
          y2="-504.744"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D7FAF7" />
          <stop offset="1" stop-color="#A9B6E8" />
        </linearGradient>
      </defs>
    </svg>
  );
}
