import React from "react";

export default function SpeakerIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V4C20 3.46957 19.7893 2.96086 19.4142 2.58579C19.0391 2.21071 18.5304 2 18 2ZM18 20H6V4H18V20Z"
        fill="#828282"
      />
      <path
        d="M12 19C12.7911 19 13.5645 18.7654 14.2223 18.3259C14.8801 17.8864 15.3928 17.2616 15.6955 16.5307C15.9983 15.7998 16.0775 14.9956 15.9231 14.2196C15.7688 13.4437 15.3878 12.731 14.8284 12.1716C14.269 11.6122 13.5563 11.2312 12.7804 11.0769C12.0044 10.9225 11.2002 11.0017 10.4693 11.3045C9.73836 11.6072 9.11365 12.1199 8.67412 12.7777C8.2346 13.4355 8 14.2089 8 15C8 16.0609 8.42143 17.0783 9.17157 17.8284C9.92172 18.5786 10.9391 19 12 19ZM12 13C12.3956 13 12.7822 13.1173 13.1111 13.3371C13.44 13.5568 13.6964 13.8692 13.8478 14.2346C13.9991 14.6001 14.0387 15.0022 13.9616 15.3902C13.8844 15.7781 13.6939 16.1345 13.4142 16.4142C13.1345 16.6939 12.7781 16.8844 12.3902 16.9616C12.0022 17.0387 11.6001 16.9991 11.2346 16.8478C10.8692 16.6964 10.5568 16.44 10.3371 16.1111C10.1173 15.7822 10 15.3956 10 15C10 14.4696 10.2107 13.9609 10.5858 13.5858C10.9609 13.2107 11.4696 13 12 13Z"
        fill="#828282"
      />
      <path
        d="M12.0098 9C13.1143 9 14.0098 8.10457 14.0098 7C14.0098 5.89543 13.1143 5 12.0098 5C10.9052 5 10.0098 5.89543 10.0098 7C10.0098 8.10457 10.9052 9 12.0098 9Z"
        fill="#828282"
      />
    </svg>
  );
}
