import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import { ReactComponent as Logo } from "../../../images/Logo.svg";
import Background2 from "../../../images/Background2.svg";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    maxWidth: 1440,
  },
  wrap: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  background: {
    backgroundImage: `url(${Background2})`,
    width: "100%",
    height: 1500,
    position: "absolute",
    zIndex: -1,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    top: -130,
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    margin: "62px 0 32px 0",
  },
  card: {
    borderRadius: 4,
    width: "90%",
    margin: "auto",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    height: "auto",
    backgroundColor: "white",
    maxWidth: 672,
  },
  errorPop: {
    left: "50%",
    width: 320,
    height: 90,
    zIndex: 500,
    marginTop: -100,
    position: "absolute",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
    marginLeft: -160,
    borderRadius: 4,
    backgroundColor: "white",
    opacity: 0,
    transition: "all 0.3s ease",
    "&::after": {
      content: `''`,
      position: "absolute",
      left: "48%",
      top: 90,
      width: "0",
      height: "0",
      border: "8px solid transparent",
      borderTopColor: "white",
      transition: "all 0.3s ease",
    },
  },
  errorPopText: {
    textAlign: "center",
    padding: 30,
    margin: "auto",
    marginTop: -5,
    zIndex: 1000,
    transition: "all 0.3s ease",
    opacity: 0,
  },
  bookingCard: {
    width: "100%",
    height: 111,
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    justifyContent: "center",
    alignItems: "center",
    padding: 40,
  },
  bookingRow: {
    display: "flex",
    flexDirection: "row",
    minWidth: "100%",
    minHeight: 55,
    borderBottom: "1px solid #f3f0f0",
  },
  bookingItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 600,
    fontStyle: "normal",
    fontSize: 14,
  },
  bookingItemName: {
    width: 130,
  },
  content: {
    margin: "auto",
    paddingLeft: 40,
    paddingRight: 40,
    maxWidth: 550,
    paddingTop: 10,
    paddingBottom: 30,
  },
  title: {
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 42,
    lineHeight: "56px",
    textAlign: "center",
    marginBottom: 29,
  },
  subtitle: {
    fontFamily: "Avenir Next LT Pro",
    fontStyle: "normal",
    fontSize: 24,
    textAlign: "center",
    fontWeight: 600,
    paddingTop: 50,
    paddingBottom: 0,
  },
  note: {
    paddingLeft: 40,
  },
  decription: {
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 600,
    fontStyle: "normal",
    fontSize: 16,
    textAlign: "center",
    margin: "auto",
    paddingBottom: 20,
    maxWidth: 888,
    width: "80%",
  },
  subDecription: {
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: 14,
    textAlign: "center",
    margin: "auto",
    paddingBottom: 20,
    maxWidth: 888,
    width: "80%",
  },
  buttonGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  button: {
    background: theme.brand,
    color: "white",
    width: 280,
    height: 62,
    margin: "16px 0 22px 0",
    backgroundColor: "black",

    "&:hover": {
      background: "#696969",
    },
  },
}));

export default function ClientHome() {
  const classes = useStyles();

  const goToSpeedoc = () => {
    window.location.href = "https://speedoc.com/sg";
  };
  return (
    <div className={classes.container}>
      <div className={classes.background} />
      <div className={classes.wrap}>
        <div className={classes.logo}>
          <Logo />
        </div>
        <div className={classes.title}>Tele-ART</div>
        <div className={classes.card}>
          <div className={classes.subtitle}>
            Supervised ART Test with Speedoc
          </div>
          <div className={classes.subDecription}>
            If you have made an appointment, you may access your Tele-ART
            meeting through the link provided in the email/SMS sent.
          </div>
          <div className={classes.decription}>
            Learn more about Speedoc’s COVID-19 services
          </div>

          <div className={classes.subDecription}>
            Stay safe and get a MOH-approved COVID-19 test done in your home or
            at our clinic. Our COVID-19 tests are valid as pre-departure testing
            as well. Protect your family and keep your loved ones safe.
          </div>
          <div className={classes.buttonGroup}>
            <Button
              className={classes.button}
              data-cy-disconnect
              onClick={goToSpeedoc}
            >
              Visit Speedoc COVID-19
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
