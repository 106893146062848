import React, { FormEvent, useState, useEffect } from "react";
import { useAppState } from "../../state";

import Button from "@material-ui/core/Button";
import { Theme } from "@material-ui/core";

import { ReactComponent as Logo } from "../../images/Logo.svg";
import Background1 from "../../images/Background1.svg";

import { makeStyles } from "@material-ui/core/styles";

import * as api from "../../utils/service";
import { uid } from "../../utils/index";
import { toastrs } from "../../utils/toastr";

const useStyles = makeStyles((theme: Theme) => ({
  googleButton: {
    background: "white",
    color: "rgb(0, 94, 166)",
    borderRadius: "4px",
    border: "2px solid rgb(2, 122, 197)",
    margin: "1.8em 0 0.7em",
    textTransform: "none",
    boxShadow: "none",
    padding: "0.3em 1em",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    "&:hover": {
      background: "white",
      boxShadow: "none"
    }
  },
  errorMessage: {
    color: "red",
    display: "flex",
    alignItems: "center",
    margin: "1em 0 0.2em",
    "& svg": {
      marginRight: "0.4em"
    }
  },
  gutterBottom: {
    marginBottom: "1em"
  },
  passcodeContainer: {
    minHeight: "50px"
  },
  submitButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    background: "#000"
  },
  fullWidth: {
    minWidth: "100%"
  },
  flexEnd: {
    justifyContent: "flex-end",
    display: "flex"
  },
  passcodeContainer1: {
    minHeight: "100px",
    marginTop: "15px"
  },
  textBold: {
    fontWeight: "bold"
  },
  container: {
    width: "100%",
    maxWidth: 1104,
    margin: "0 auto"
  },
  background: {
    backgroundImage: `url(${Background1})`,
    width: "100%",
    maxWidth: 1104,
    height: 950,
    position: "absolute",
    zIndex: -1,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    margin: "auto",
    marginTop: -100
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    margin: "0 0 32px 0"
  },
  content: {
    margin: 0,
    paddingLeft: 20,
    fontSize: 13
  },
  subtitle1: {
    fontFamily: "Avenir Next LT Pro",
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: 16,
    lineHeight: "30px",
    textAlign: "center",
    marginBottom: 21
  },
  card: {
    borderRadius: 4,
    width: "90%",
    maxWidth: 672,
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: "white",
    padding: 40,
    margin: "auto",
    marginTop: 50,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: 100
  },
  buttonGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  dashboardLinkContainer: {
    position: "relative",
    float: "right",
    marginTop: "-50px",
    paddingRight: "215px"
  },
  dashboardLink: {
    color: "#484848",
    fontSize: "18px",
    fontWeight: "600"
  },
  waitingCount: {
    textAlign: "center"
  },
  button: {
    background: theme.brand,
    color: "white",
    width: 280,
    height: 62,
    fontSize: 18,
    margin: "16px 0 22px 0",
    backgroundColor: "#00c3b5",

    "&:hover": {
      background: "#00a295"
    }
  }
}));

export default function GetNextClientPage() {
  const classes = useStyles();
  const { isAuthReady, user } = useAppState();
  const [isFetching, setIsFetching] = useState(false);
  const [oldCount, setOldCount] = useState(null);
  const [newCount, setNewCount] = useState(null);
  const audio = new Audio("sounds/2_JoinCall.mp3");

  useEffect(() => {
    if (!oldCount && !!newCount) {
      audio.play();
    } else {
      audio.pause();
    }
    setOldCount(newCount);
  }, [newCount]);

  useEffect(() => {
    const myInterval = setInterval(async () => {
      const response = await api.getWaitingCount();
      setNewCount(response?.count);
    }, 5000);
    return () => clearInterval(myInterval);
  }, []);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsFetching(true);
    let rp = await api.getNextClient();
    if (rp?.client) {
      setIsFetching(false);
      window.open(
        window.location.origin +
          `/connect/supervisor/${rp.client?.bookingUuid}/${rp.client?.twilio?.accessTokens?.supervisor}`,
        "_blank",
        'fullscreen="yes"'
      );
    } else {
      toastrs.show("success", "No client found!");
      setIsFetching(false);
    }
  };

  if (!isAuthReady) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.background} />
      <div className={classes.wrap}>
        <div className={classes.dashboardLinkContainer}>
          <a className={classes.dashboardLink} href="provider/dashboard">
            Provider Dashboard >
          </a>
        </div>
        <div className={classes.card}>
          <div className={classes.logo}>
            <Logo />
          </div>
          <form onSubmit={handleSubmit}>
            <div>
              <p className={classes.waitingCount}>
                Total number of clients in the waiting room: {newCount}
              </p>
            </div>
            <div className={classes.buttonGroup}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={false}
                className={`${classes.button} ${classes.fullWidth}`}
              >
                Get Next Client
              </Button>
            </div>
            <div className={classes.subtitle1}>
              Click on the button above to attend to the next patient in queue.
              <br />
              <br />A new window will open up, you may adjust the windows on
              your screen to view all patients at the same time.
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
